var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"first-static-table"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',[_vm._l((_vm.categories),function(category,catIndex){return [_c('tr',{key:catIndex,staticStyle:{"height":"75px"}},[_c('td',{staticClass:"has-background-primary has-text-white"},[_vm._v(" "+_vm._s(category.categoryName)+" ")]),_c('td',{staticClass:"has-background-primary"},[_vm._v(" ")]),_vm._m(1,true),_c('td',{staticClass:"has-background-primary"},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('span',{staticClass:"has-text-white has-text-weight-bold"},[_vm._v(_vm._s(category.rad))])])]),(_vm.isEditing)?_c('td',{staticClass:"has-background-primary"},[_vm._v(" ")]):_vm._e()]),_vm._l((category.prestations),function(prestation){return [_c('tr',{key:'p-' + prestation.prestationId,staticStyle:{"height":"75px"}},[_c('td',[_vm._v(_vm._s(prestation.subCategoryName))]),_c('td',[_vm._v(_vm._s(prestation.prestationName))]),_vm._m(2,true),_c('td',[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('span',{class:{
                      danger:
                        _vm.prestationVariances[prestation.prestationId] !== 0,
                    },attrs:{"title":_vm.prestationVariances[prestation.prestationId]}},[_vm._v(_vm._s(prestation.rad))])])]),(_vm.isEditing)?_c('td',[_c('span',{on:{"click":function($event){return _vm.smooth(prestation, 'all')}}},[_c('font-awesome-icon',{staticClass:"is-info",attrs:{"icon":"redo-alt"}})],1)]):_vm._e()])]})]})],2)])]),_c('div',{staticClass:"sheet-data",staticStyle:{"overflow":"auto","width":"100%"}},[_c('table',{staticClass:"table",staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_vm._l((_vm.forecast),function(yearData,yearIndex){return [_c('th',{key:yearIndex,staticClass:"has-text-centered nowrap",attrs:{"colspan":yearData.collapsed ? 1 : Object.keys(yearData.months).length}},[_c('div',{staticClass:"button is-small is-light collapse-button mr-2",on:{"click":function($event){return _vm.$set(_vm.forecast[yearIndex], 'collapsed', !yearData.collapsed)}}},[(!yearData.collapsed)?_c('font-awesome-icon',{attrs:{"icon":"minus"}}):_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1),_c('span',[_vm._v(_vm._s(yearData.year))])])]})],2),_c('tr',[_vm._l((_vm.forecast),function(yearData,yearIndex){return [_vm._l((yearData.months),function(month,monthIndex){return [_c('th',{key:(yearIndex + "-" + monthIndex),staticClass:"has-text-centered",class:{
                  collapsed: yearData.collapsed,
                  'table-border': monthIndex === 0,
                }},[_vm._v(" "+_vm._s(_vm._f("monthName")(month.month))+" ")])]}),(yearData.collapsed)?_c('th',{key:yearIndex,staticClass:"table-border"},[_vm._v("   ")]):_vm._e()]})],2)]),_c('tbody',[_vm._l((_vm.categories),function(category,catIndex){return [_c('tr',{key:catIndex,staticStyle:{"height":"75px"}},[_vm._l((_vm.forecast),function(yearData,yearIndex){return [_vm._l((yearData.months),function(month,monthIndex){return [_c('td',{key:(yearIndex + "-" + monthIndex),staticClass:"has-background-primary has-text-centered",class:{
                    collapsed: yearData.collapsed,
                    'table-border': monthIndex === 0,
                  },staticStyle:{"height":"60px"}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('span',{staticClass:"has-text-white has-text-weight-bold"},[_vm._v(_vm._s(month.categories[category.categoryId]))])])])]}),(yearData.collapsed)?_c('td',{key:yearIndex,staticClass:"table-border has-background-primary has-text-centered",staticStyle:{"height":"60px"}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('span',{staticClass:"has-text-white has-text-weight-bold"},[_vm._v(_vm._s(yearData.categories[category.categoryId]))])])]):_vm._e()]})],2),_vm._l((category.prestations),function(prestation){return [_c('tr',{key:'p-' + prestation.prestationId,staticStyle:{"height":"75px"}},[_vm._l((_vm.forecast),function(yearData,yearIndex){return [_vm._l((yearData.months),function(month,monthIndex){return [_c('td',{key:((yearData.year) + "-" + (month.month)),staticClass:"has-text-centered nowrap",class:{
                      collapsed: yearData.collapsed,
                      'table-border': monthIndex === 0,
                    },staticStyle:{"height":"60px"}},[_c('div',[(!_vm.isEditing)?_c('span',[_vm._v(" "+_vm._s(month.prestations[prestation.prestationId])+" ")]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                          _vm.forecast[yearIndex].months[monthIndex].prestations[
                            prestation.prestationId
                          ]
                        ),expression:"\n                          forecast[yearIndex].months[monthIndex].prestations[\n                            prestation.prestationId\n                          ]\n                        "}],staticClass:"input",domProps:{"value":(
                          _vm.forecast[yearIndex].months[monthIndex].prestations[
                            prestation.prestationId
                          ]
                        )},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.forecast[yearIndex].months[monthIndex].prestations, 
                            prestation.prestationId
                          , $event.target.value)},function($event){_vm.onFieldInput(
                            parseInt(yearIndex),
                            yearData.year,
                            parseInt(monthIndex),
                            month.month,
                            category.categoryId,
                            prestation,
                            month.prestations[prestation.prestationId]
                          )}]}})]),(
                        _vm.isEditing &&
                        _vm.editingField &&
                        _vm.editingField.year === yearData.year &&
                        _vm.editingField.month === month.month &&
                        _vm.editingField.prestationId === prestation.prestationId
                      )?_c('div',{staticClass:"is-flex is-justify-content-space-around"},[(!(yearIndex == 0 && monthIndex == 0))?_c('span',{attrs:{"title":"lisse à gauche"},on:{"click":function($event){return _vm.smooth(prestation, 'before')}}},[_c('font-awesome-icon',{staticClass:"is-one-directional",attrs:{"icon":"chevron-left","title":_vm.difference &&
                            _vm.difference[
                              prestation.prestationId
                            ].right.toString()}})],1):_vm._e(),(
                          !(
                            (yearIndex == 0 && monthIndex == 0) ||
                            (yearIndex == Object.keys(_vm.forecast).length - 1 &&
                              monthIndex ==
                                Object.keys(yearData.months).length - 1)
                          )
                        )?_c('span',{staticClass:"is-bi-directional",attrs:{"title":"lisser le reste"},on:{"click":function($event){return _vm.smooth(prestation, 'rest')}}},[_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h","title":_vm.difference &&
                            _vm.difference[
                              prestation.prestationId
                            ].editing.toString()}})],1):_vm._e(),(
                          !(
                            yearIndex == Object.keys(_vm.forecast).length - 1 &&
                            monthIndex ==
                              Object.keys(yearData.months).length - 1
                          )
                        )?_c('span',{attrs:{"title":"lisse à droite"},on:{"click":function($event){return _vm.smooth(prestation, 'after')}}},[_c('font-awesome-icon',{staticClass:"is-one-directional",attrs:{"icon":"chevron-right","title":_vm.difference &&
                            _vm.difference[
                              prestation.prestationId
                            ].left.toString()}})],1):_vm._e()]):_vm._e()])]}),(yearData.collapsed)?_c('td',{key:yearIndex,staticClass:"table-border has-text-centered",staticStyle:{"height":"60px"}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('span',[_vm._v(_vm._s(yearData.prestations[prestation.prestationId]))])])]):_vm._e()]})],2)]})]})],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"5"}},[_vm._v(" ")])]),_c('tr',[_c('th',[_vm._v("Categorie")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Prestation")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Total")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"has-background-primary has-text-white"},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('span',{staticClass:"has-text-weight-bold"},[_vm._v("RAD")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('span',[_vm._v("RAD")])])])}]

export { render, staticRenderFns }