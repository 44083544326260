<template>
  <table ref="table" class="table">
    <colgroup>
      <col span="2" />
      <col :class="{ 'collapsed-border': columnsCollapsed.budget }" />
      <col :span="columnsCollapsed.budget ? 2 : 3" />
      <col :class="{ 'collapsed-border': columnsCollapsed.isf }" />
      <col :span="columnsCollapsed.isf ? 6 : 7" />
      <col :class="{ 'collapsed-border': columnsCollapsed.breakdown }" />
      <col :span="columnsCollapsed.breakdown ? 2 : 3" />
      <col :class="{ 'collapsed-border': columnsCollapsed.oscar }" />
    </colgroup>
    <thead>
      <tr>
        <th rowspan="2" class="has-background-white">Catégorie</th>
        <th rowspan="2" class="has-background-white">Prestation</th>
        <th rowspan="2" class="has-background-white">
          <div
            class="dropdown"
            :class="{ 'is-active': referenceBudgetSelectorActive }"
          >
            <div class="dropdown-trigger">
              <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                @click="
                  referenceBudgetSelectorActive = !referenceBudgetSelectorActive
                "
              >
                <div>
                  <b>{{
                    referenceBudgetSelected.milestone
                      ? referenceBudgetSelected.name
                      : referenceBudgetSelected
                  }}</b>
                </div>
                <font-awesome-icon icon="angle-down" />
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <div
                  v-for="(referenceBudget, key) in referenceBudgets"
                  :key="key"
                  class="dropdown-item"
                  @click="selectReferenceBudget(referenceBudget)"
                >
                  {{ referenceBudget.name }}
                </div>
              </div>
            </div>
          </div>
        </th>
        <th
          :colspan="columnsCollapsed.budget ? '1' : '2'"
          class="has-background-white"
          :class="{ collapsed: columnsCollapsed.budget && !cdpBudget }"
        >
          <div class="is-relative">
            Budget
            <div
              class="button is-small is-light collapse-button"
              @click="columnsCollapsed.budget = !columnsCollapsed.budget"
              title="Budget"
            >
              <font-awesome-icon icon="minus" v-if="!columnsCollapsed.budget" />
              <font-awesome-icon icon="plus" v-else />
            </div>
          </div>
        </th>
        <th colspan="2" class="has-background-light">Dépenses</th>
        <th
          colspan="2"
          class="has-background-white"
          :class="{ collapsed: columnsCollapsed.isf }"
        >
          <div class="is-relative">
            ISF
            <div
              class="button is-small is-light collapse-button"
              @click="columnsCollapsed.isf = !columnsCollapsed.isf"
              title="ISF"
            >
              <font-awesome-icon icon="minus" v-if="!columnsCollapsed.isf" />
              <font-awesome-icon icon="plus" v-else />
            </div>
          </div>
        </th>
        <th rowspan="2" class="has-background-light">CAP/FNP</th>
        <th rowspan="2" class="has-background-white">RAD</th>
        <th rowspan="2" class="has-background-light">Dépenses FDC</th>
        <th colspan="3" class="has-background-white">Dépenses FDC vs</th>
        <th
          rowspan="2"
          class="has-background-light"
          :class="{ collapsed: columnsCollapsed.breakdown }"
        >
          <div class="is-relative">
            % budget tâche / budget total
            <div
              class="button is-small is-light collapse-button"
              @click="columnsCollapsed.breakdown = !columnsCollapsed.breakdown"
              title="% budget tâche / budget total"
            >
              <font-awesome-icon
                icon="minus"
                v-if="!columnsCollapsed.breakdown"
              />
              <font-awesome-icon icon="plus" v-else />
            </div>
          </div>
        </th>
        <th colspan="2" class="has-background-white">Avancement</th>
        <th
          colspan="3"
          class="has-background-light"
          :class="{ collapsed: columnsCollapsed.oscar }"
        >
          <div class="is-relative">
            OSCAR
            <div
              class="button is-small is-light collapse-button"
              @click="columnsCollapsed.oscar = !columnsCollapsed.oscar"
              title="OSCAR"
            >
              <font-awesome-icon icon="minus" v-if="!columnsCollapsed.oscar" />
              <font-awesome-icon icon="plus" v-else />
            </div>
          </div>
        </th>
      </tr>
      <tr>
        <th
          class="has-background-white inline-border"
          v-if="!(columnsCollapsed.budget && cdpBudget === 'Exe')"
          :class="{ collapsed: columnsCollapsed.budget && cdpBudget !== 'Aps' }"
        >
          APS
        </th>
        <th
          class="has-background-white inline-border"
          v-if="!(columnsCollapsed.budget && cdpBudget !== 'Exe')"
        >
          EXE
        </th>
        <th class="has-background-light inline-border">Au stade</th>
        <th class="has-background-light inline-border">
          {{
            sheet.project && sheet.project.doubleMonth ? "M / M-1" : "Du mois"
          }}
        </th>
        <th
          class="has-background-white inline-border"
          :class="{ collapsed: columnsCollapsed.isf }"
        >
          Commandé
        </th>
        <th
          class="has-background-white inline-border"
          v-if="!columnsCollapsed.isf"
        >
          Facturé
        </th>
        <th class="has-background-white inline-border">FDC M-1</th>
        <th class="has-background-white inline-border">Budget de référence</th>
        <th class="has-background-white inline-border">Budget initial CDP</th>
        <th class="collapsed" v-if="columnsCollapsed.breakdown"></th>
        <th class="has-background-white inline-border">Financier</th>
        <th class="has-background-white inline-border">Physique</th>
        <th
          class="has-background-light inline-border"
          v-if="!columnsCollapsed.oscar"
        >
          Date début de tâche
        </th>
        <th
          class="has-background-light inline-border"
          v-if="!columnsCollapsed.oscar"
        >
          Date fin de tâche
        </th>
        <th
          class="has-background-light inline-border"
          v-if="!columnsCollapsed.oscar"
        >
          Avancement
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(category, catIndex) in categories">
        <!-- SUB TOTALS -->
        <tr class="sub-total" :key="catIndex">
          <td class="is-info" colspan="2">{{ category.categoryName }}</td>
          <td class="is-info has-text-right">
            {{
              selectedBudgetAmounts[category.categoryName]
                ? selectedBudgetAmounts[category.categoryName]
                : 0 | number
            }}
          </td>
          <td
            class="is-info has-text-right"
            v-if="!(columnsCollapsed.budget && cdpBudget === 'Exe')"
            :class="{
              collapsed: columnsCollapsed.budget && cdpBudget !== 'Aps',
            }"
          >
            {{ cdpBudget ? category.amountAps : 0 | number }}
          </td>
          <td
            class="is-info has-text-right"
            v-if="!(columnsCollapsed.budget && cdpBudget !== 'Exe')"
          >
            {{ cdpBudget === "Exe" ? category.amountExe : 0 | number }}
          </td>
          <td class="is-info has-text-right">
            {{
              monthSelected === "complete"
                ? category.phaseExpenses
                : category.totalExpenses | number
            }}
          </td>
          <td class="is-info has-text-right">
            {{
              monthSelected === "complete"
                ? category.monthExpenses
                : category.currentExpenses | number
            }}
          </td>
          <td
            class="is-info has-text-right"
            :class="{ collapsed: columnsCollapsed.isf }"
          >
            {{ category.isfOsAmount | number }}
          </td>
          <td class="is-info has-text-right" v-if="!columnsCollapsed.isf">
            {{ category.isfInvoiced | number }}
          </td>
          <td class="is-info has-text-right">{{ category.capFnp | number }}</td>
          <td class="is-info has-text-right">{{ category.rad | number }}</td>
          <td class="is-info has-text-right">
            {{ category.budgetAmount | number }}
          </td>
          <td class="is-info has-text-right">
            {{ (category.budgetAmount - category.previousAmount) | number }}
          </td>
          <td class="is-info has-text-right">
            {{
              selectedBudgetAmounts[category.categoryName]
                ? category.budgetAmount -
                  selectedBudgetAmounts[category.categoryName]
                : category.budgetAmount | number
            }}
          </td>
          <td class="is-info has-text-right">
            {{
              cdpBudget
                ? category.budgetAmount - category["amount" + cdpBudget]
                : category.budgetAmount | number
            }}
          </td>
          <td
            class="is-info has-text-centered"
            :class="{ collapsed: columnsCollapsed.breakdown }"
          >
            {{
              totalBudget === 0
                ? 0
                : (category.budgetAmount / totalBudget) | percentage
            }}
          </td>
          <td class="is-info has-text-centered">
            {{ category.financialProgress | percentage(2) }}
          </td>
          <td class="is-info has-text-centered">
            {{ category.progress | percentage(2) }}
          </td>
          <template v-if="!columnsCollapsed.oscar">
            <td
              class="is-info has-text-centered"
              v-if="category.oscarStartDate"
            >
              {{ category.oscarStartDate | reverseDate }}
            </td>
            <td class="is-info has-text-centered" v-else></td>
            <td class="is-info has-text-centered" v-if="category.oscarEndDate">
              {{ category.oscarEndDate | reverseDate }}
            </td>
            <td class="is-info has-text-centered" v-else></td>
            <td class="is-info has-text-centered">
              {{ category.oscarProgress | percentage }}
            </td>
          </template>
        </tr>
        <template v-for="(prestation, pIndex) in category.prestations">
          <!-- PRESTATIONS -->
          <tr :key="'p-' + prestation.id">
            <td>{{ prestation.subCategoryName }}</td>
            <td class="is-relative">
              {{ prestation.prestationName }}
              <CommentInline
                :sheet="sheet"
                :item="prestation"
                type="prestation"
              />
            </td>
            <td class="has-text-right">
              {{
                selectedBudgetAmounts[prestation.prestationId]
                  ? selectedBudgetAmounts[prestation.prestationId]
                  : 0 | number
              }}
            </td>
            <td
              class="has-text-right clickable"
              v-if="!(columnsCollapsed.budget && cdpBudget === 'Exe')"
              :class="{
                collapsed: columnsCollapsed.budget && cdpBudget !== 'Aps',
              }"
              @click="openBudgetPrestationDetail(prestation.prestationsAps)"
            >
              {{ cdpBudget ? prestation.amountAps : 0 | number }}
            </td>
            <td
              class="has-text-right clickable"
              v-if="!(columnsCollapsed.budget && cdpBudget !== 'Exe')"
              @click="openBudgetPrestationDetail(prestation.prestationsExe)"
            >
              {{ cdpBudget === "Exe" ? prestation.amountExe : 0 | number }}
            </td>
            <td
              class="has-text-right has-background-light clickable"
              @click="openSapDetail(prestation.sapRecords)"
            >
              {{
                monthSelected === "complete"
                  ? prestation.phaseExpenses
                  : prestation.totalExpenses | number
              }}
            </td>
            <td
              class="has-text-right has-background-light clickable"
              @click="openSapDetail(prestation.sapRecords, true)"
            >
              {{
                monthSelected === "complete"
                  ? prestation.monthExpenses
                  : prestation.currentExpenses | number
              }}
            </td>
            <td
              class="has-text-right clickable"
              :class="{ collapsed: columnsCollapsed.isf }"
              @click="openIsfDetail(prestation.isfRecords)"
            >
              {{ prestation.isfOsAmount | number }}
            </td>
            <td
              class="has-text-right clickable"
              v-if="!columnsCollapsed.isf"
              @click="openIsfDetail(prestation.isfRecords)"
            >
              {{ prestation.isfInvoiced | number }}
            </td>
            <td
              class="has-text-right has-background-light"
              :style="isEditing ? { minWidth: '150px' } : null"
            >
              <text-field
                :dataTest="'cap-fnp-' + prestation.prestationName"
                type="number"
                :value="prestation.capFnp"
                @input="updateCapFnp($event, catIndex, pIndex)"
                :edit="isEditing"
              />
            </td>
            <td
              class="has-text-right"
              :style="isEditing ? { minWidth: '150px' } : null"
            >
              <text-field
                :dataTest="'rad-' + prestation.prestationName"
                type="number"
                :value="prestation.rad"
                @input="updateRad($event, catIndex, pIndex)"
                :edit="isEditing"
              />
            </td>
            <td class="has-text-right has-background-light">
              {{ prestation.amount | number }}
            </td>
            <td class="has-text-right">
              {{ (prestation.amount - prestation.previousAmount) | number }}
            </td>
            <td class="has-text-right">
              {{
                selectedBudgetAmounts[prestation.prestationId]
                  ? prestation.amount -
                    selectedBudgetAmounts[prestation.prestationId]
                  : prestation.amount | number
              }}
            </td>
            <td class="has-text-right">
              {{
                cdpBudget
                  ? prestation.amount - prestation["amount" + cdpBudget]
                  : prestation.amount | number
              }}
            </td>
            <td
              class="has-text-centered has-background-light"
              :class="{ collapsed: columnsCollapsed.breakdown }"
            >
              {{
                totalBudget == 0
                  ? 0
                  : (prestation.amount / totalBudget) | percentage
              }}
            </td>
            <td class="has-text-centered">
              {{
                prestation.amount == 0
                  ? 0
                  : (prestation.phaseExpenses / prestation.amount)
                    | percentage(2)
              }}
            </td>
            <td
              class="has-text-centered"
              :style="isEditing ? { minWidth: '110px' } : null"
            >
              <percentage-field
                :value="prestation.progress"
                :decimals="2"
                @input="updateProgress($event, catIndex, pIndex)"
                :edit="isEditing"
              />
            </td>
            <template v-if="!columnsCollapsed.oscar">
              <td
                class="has-text-centered has-background-light"
                v-if="prestation.oscarStartDate"
              >
                {{ prestation.oscarStartDate | reverseDate }}
              </td>
              <td class="has-text-centered has-background-light" v-else></td>
              <td
                class="has-text-centered has-background-light"
                v-if="prestation.oscarEndDate"
              >
                {{ prestation.oscarEndDate | reverseDate }}
              </td>
              <td class="has-text-centered has-background-light" v-else></td>
              <td class="has-text-centered has-background-light">
                {{ prestation.oscarProgress | percentage }}
              </td>
            </template>
          </tr>
        </template>
      </template>
      <!-- UNAFFECTED -->
      <tr v-if="sheet.unaffectedPrestation" class="has-background-danger-light">
        <td colspan="3">{{ sheet.unaffectedPrestation.subCategoryName }}</td>
        <td :colspan="columnsCollapsed.budget ? '1' : '2'"></td>
        <td
          class="has-text-right clickable"
          @click="openSapDetail(sheet.unaffectedPrestation.sapRecords)"
          :cy-data="'totalUnaffectedSpent'"
        >
          {{
            monthSelected === "complete"
              ? sheet.unaffectedPrestation.phaseExpenses
              : sheet.unaffectedPrestation.totalExpenses | number
          }}
        </td>
        <td
          class="has-text-right clickable"
          @click="openSapDetail(sheet.unaffectedPrestation.sapRecords, true)"
        >
          {{
            monthSelected === "complete"
              ? sheet.unaffectedPrestation.monthExpenses
              : sheet.unaffectedPrestation.currentExpenses | number
          }}
        </td>
        <td
          class="has-text-right clickable isf"
          :class="{ collapsed: columnsCollapsed.isf }"
          @click="openIsfDetail(sheet.unaffectedPrestation.isfRecords)"
          :cy-data="'totalUnaffectedOrdered'"
        >
          {{ sheet.unaffectedPrestation.isfOsAmount | number }}
        </td>
        <td
          class="has-text-right clickable isf"
          v-if="!columnsCollapsed.isf"
          @click="openIsfDetail(sheet.unaffectedPrestation.isfRecords)"
          :cy-data="'totalUnaffectedInvoiced'"
        >
          {{ sheet.unaffectedPrestation.isfInvoiced | number }}
        </td>
        <td colspan="2"></td>
        <td class="has-text-right">
          {{
            monthSelected === "complete"
              ? sheet.unaffectedPrestation.phaseExpenses
              : sheet.unaffectedPrestation.totalExpenses | number
          }}
        </td>
        <td class="has-text-right">
          {{
            monthSelected === "complete"
              ? sheet.unaffectedPrestation.phaseExpenses
              : sheet.unaffectedPrestation.totalExpenses | number
          }}
        </td>
        <td class="has-text-right">
          {{
            monthSelected === "complete"
              ? sheet.unaffectedPrestation.phaseExpenses
              : sheet.unaffectedPrestation.totalExpenses | number
          }}
        </td>
        <td class="has-text-right">
          {{
            monthSelected === "complete"
              ? sheet.unaffectedPrestation.phaseExpenses
              : sheet.unaffectedPrestation.totalExpenses | number
          }}
        </td>
        <td
          class="has-text-centered"
          :class="{ collapsed: columnsCollapsed.breakdown }"
        >
          {{
            totalBudget == 0
              ? 0
              : (sheet.unaffectedPrestation.phaseExpenses / totalBudget)
                | percentage
          }}
        </td>
        <td class="has-text-centered">100,00 %</td>
        <td class="has-text-centered">100,00 %</td>
        <td colspan="3" v-if="!columnsCollapsed.oscar"></td>
      </tr>
      <!-- TOTAL -->
      <tr class="total">
        <td class="is-info" colspan="2">Dépenses Affaire</td>
        <td class="is-info has-text-right">
          {{
            selectedBudgetAmounts.total
              ? selectedBudgetAmounts.total
              : 0 | number
          }}
        </td>
        <td
          class="is-info has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget === 'Exe')"
          :class="{ collapsed: columnsCollapsed.budget && cdpBudget !== 'Aps' }"
        >
          {{ cdpBudget ? sheet.totalAmountAps : 0 | number }}
        </td>
        <td
          class="is-info has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget !== 'Exe')"
        >
          {{ cdpBudget == "Exe" ? sheet.totalAmountExe : 0 | number }}
        </td>
        <td class="is-info has-text-right" cy-data="totalPhaseExpenses">
          {{
            monthSelected === "complete"
              ? sheet.totalPhaseExpenses
              : sheet.totalExpenses | number
          }}
        </td>
        <td class="is-info has-text-right" cy-data="totalMonthExpenses">
          {{
            monthSelected === "complete"
              ? sheet.totalMonthExpenses
              : sheet.totalCurrentExpenses | number
          }}
        </td>
        <td
          class="is-info has-text-right"
          :class="{ collapsed: columnsCollapsed.isf }"
          cy-data="totalIsfOsAmount"
        >
          {{ sheet.totalIsfOsAmount | number }}
        </td>
        <td
          class="is-info has-text-right"
          v-if="!columnsCollapsed.isf"
          cy-data="totalIsfInvoiced"
        >
          {{ sheet.totalIsfInvoiced | number }}
        </td>
        <td class="is-info has-text-right" cy-data="totalCapFnp">
          {{ sheet.totalCapFnp | number }}
        </td>
        <td class="is-info has-text-right" cy-data="totalRad">
          {{ sheet.totalRad | number }}
        </td>
        <td class="is-info has-text-right" cy-data="totalBudget">
          {{ totalBudget | number }}
        </td>
        <td class="is-info has-text-right" cy-data="budgetVsM-1">
          {{ (totalBudget - sheet.totalPreviousAmount) | number }}
        </td>
        <td class="is-info has-text-right">
          {{ (totalBudget - selectedBudgetAmounts.total) | number }}
        </td>
        <td class="is-info has-text-right">
          {{
            cdpBudget
              ? totalBudget - sheet["totalAmount" + cdpBudget]
              : totalBudget | number
          }}
        </td>
        <td
          class="is-info"
          :class="{ collapsed: columnsCollapsed.breakdown }"
          colspan="1"
        ></td>
        <td
          class="is-info has-text-centered"
          :cy-data="'totalFinancialProgress'"
        >
          {{
            totalBudget == 0
              ? 0
              : (sheet.totalPhaseExpenses / totalBudget) | percentage(2)
          }}
        </td>
        <td class="is-info has-text-centered" :cy-data="'totalProgress'">
          {{ sheet.totalProgress | percentage(2) }}
        </td>
        <template v-if="!columnsCollapsed.oscar">
          <td class="is-info has-text-centered" v-if="sheet.oscarStartDate">
            {{ sheet.oscarStartDate | reverseDate }}
          </td>
          <td class="is-info has-text-centered" v-else></td>
          <td class="is-info has-text-centered" v-if="sheet.oscarEndDate">
            {{ sheet.oscarEndDate | reverseDate }}
          </td>
          <td class="is-info has-text-centered" v-else></td>
          <td class="is-info has-text-centered">
            {{ sheet.totalOscarProgress | percentage }}
          </td>
        </template>
      </tr>
      <tr>
        <td colspan="21"></td>
      </tr>
      <!-- CATEGORY INCOMES -->
      <tr
        class="sub-total"
        v-for="(income, index) in categories"
        :key="'i-' + index"
      >
        <td class="is-info" colspan="2">Recettes {{ income.categoryName }}</td>
        <td class="is-info has-text-right">
          {{
            selectedIncomesAmounts[income.categoryName]
              ? selectedIncomesAmounts[income.categoryName]
              : 0 | number
          }}
        </td>
        <td
          class="is-info has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget === 'Exe')"
          :class="{ collapsed: columnsCollapsed.budget && cdpBudget !== 'Aps' }"
        >
          {{ cdpBudget ? income.incomeAps : 0 | number }}
        </td>
        <td
          class="is-info has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget !== 'Exe')"
        >
          {{ cdpBudget == "Exe" ? income.incomeExe : 0 | number }}
        </td>
        <td class="is-info" colspan="2"></td>
        <td
          class="is-info has-text-right"
          :colspan="columnsCollapsed.isf ? '3' : '4'"
        ></td>
        <td class="is-info has-text-right">{{ income.incomes | number }}</td>
        <td class="is-info has-text-right">
          {{ income.totalIncomes | number }}
        </td>
        <td class="is-info" colspan="2"></td>
        <td class="is-info" colspan="6"></td>
      </tr>
      <!-- TOTAL INCOMES -->
      <tr class="total">
        <td class="is-info" colspan="2">Recettes Affaire</td>
        <td class="is-info has-text-right">
          {{
            selectedIncomesAmounts.total
              ? selectedIncomesAmounts.total
              : 0 | number
          }}
        </td>
        <td
          class="is-info has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget === 'Exe')"
          :class="{ collapsed: columnsCollapsed.budget && cdpBudget !== 'Aps' }"
        >
          {{ cdpBudget ? sheet.totalIncomesAps : 0 | number }}
        </td>
        <td
          class="is-info has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget !== 'Exe')"
        >
          {{ cdpBudget === "Exe" ? sheet.totalIncomesExe : 0 | number }}
        </td>
        <td class="is-info" colspan="2"></td>
        <td
          class="is-info has-text-right"
          :colspan="columnsCollapsed.isf ? '3' : '4'"
        ></td>
        <td class="is-info has-text-right">
          {{ sheet.totalIncomes | number }}
        </td>
        <td class="is-info has-text-right">
          {{ sheet.totalIncomesPreviousMonth | number }}
        </td>
        <td class="is-info" colspan="2"></td>
        <td class="is-info" colspan="6"></td>
      </tr>
      <tr>
        <td colspan="21"></td>
      </tr>
      <!-- CATEGORY MARGIN -->
      <tr
        class="sub-total"
        v-for="(category, index) in categories"
        :key="'mb-' + index"
      >
        <td class="is-primary" colspan="2">MB {{ category.categoryName }}</td>
        <td class="is-primary has-text-right">
          {{
            selectedIncomesAmounts[category.categoryName] &&
            selectedBudgetAmounts[category.categoryName]
              ? selectedIncomesAmounts[category.categoryName] -
                selectedBudgetAmounts[category.categoryName]
              : selectedIncomesAmounts[category.categoryName] &&
                !selectedBudgetAmounts[category.categoryName]
              ? selectedIncomesAmounts[category.categoryName]
              : !selectedIncomesAmounts[category.categoryName] &&
                selectedBudgetAmounts[category.categoryName]
              ? 0 - selectedBudgetAmounts[category.categoryName]
              : 0 | number
          }}
        </td>
        <td
          class="is-primary has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget === 'Exe')"
          :class="{ collapsed: columnsCollapsed.budget && cdpBudget !== 'Aps' }"
        >
          {{ cdpBudget ? category.incomeAps - category.amountAps : 0 | number }}
        </td>
        <td
          class="is-primary has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget !== 'Exe')"
        >
          {{
            cdpBudget === "Exe"
              ? category.incomeExe - category.amountExe
              : 0 | number
          }}
        </td>
        <td class="is-primary" colspan="2"></td>
        <td
          class="is-primary has-text-right"
          :colspan="columnsCollapsed.isf ? '3' : '4'"
        ></td>
        <td class="is-primary has-text-right">
          {{ category.mbCategory | number }}
        </td>
        <td class="is-primary has-text-right">
          {{ category.totalMargin | number }}
        </td>
        <td class="is-primary" colspan="2"></td>
        <td class="is-primary" colspan="6"></td>
      </tr>
      <!-- TOTAL MARGIN -->
      <tr class="total">
        <td class="is-primary" colspan="2">MB Affaire</td>
        <td class="is-primary has-text-right">
          {{
            (selectedIncomesAmounts.total - selectedBudgetAmounts.total)
              | number
          }}
        </td>
        <td
          class="is-primary has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget === 'Exe')"
          :class="{ collapsed: columnsCollapsed.budget && cdpBudget !== 'Aps' }"
        >
          {{
            cdpBudget
              ? sheet.totalIncomesAps - sheet.totalAmountAps
              : 0 | number
          }}
        </td>
        <td
          class="is-primary has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget !== 'Exe')"
        >
          {{
            cdpBudget === "Exe"
              ? sheet.totalIncomesExe - sheet.totalAmountExe
              : 0 | number
          }}
        </td>
        <td class="is-primary" colspan="2"></td>
        <td
          class="is-primary has-text-right"
          :colspan="columnsCollapsed.isf ? '3' : '4'"
        ></td>
        <td class="is-primary has-text-right">
          {{ (sheet.totalIncomes - totalBudget) | number }}
        </td>
        <td class="is-primary has-text-right">
          {{ sheet.previousHistorizationMbAffaire | number }}
        </td>
        <td class="is-primary" colspan="2"></td>
        <td class="is-primary" colspan="6"></td>
      </tr>
      <tr class="total">
        <td class="is-primary" colspan="2">% MB Affaire</td>
        <td class="is-primary has-text-right">
          {{
            selectedIncomesAmounts.total !== 0
              ? 1 - selectedBudgetAmounts.total / selectedIncomesAmounts.total
              : 0 | percentage(2)
          }}
        </td>
        <td
          class="is-primary has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget === 'Exe')"
          :class="{ collapsed: columnsCollapsed.budget && cdpBudget !== 'Aps' }"
        >
          {{
            cdpBudget && sheet.totalIncomesAps !== 0
              ? 1 - sheet.totalAmountAps / sheet.totalIncomesAps
              : 0 | percentage(2)
          }}
        </td>
        <td
          class="is-primary has-text-right"
          v-if="!(columnsCollapsed.budget && cdpBudget !== 'Exe')"
        >
          {{
            cdpBudget === "Exe" && sheet.totalIncomesExe !== 0
              ? 1 - sheet.totalAmountExe / sheet.totalIncomesExe
              : 0 | percentage(2)
          }}
        </td>
        <td class="is-primary" colspan="2"></td>
        <td
          class="is-primary has-text-right"
          :colspan="columnsCollapsed.isf ? '3' : '4'"
        ></td>
        <td class="is-primary has-text-right">
          {{
            sheet.totalIncomes !== 0
              ? 1 - totalBudget / sheet.totalIncomes
              : 0 | percentage(2)
          }}
        </td>
        <td class="is-primary has-text-right">
          {{ sheet.previousPercentMbAffaire | percentage(2) }}
        </td>
        <td class="is-primary" colspan="2"></td>
        <td class="is-primary" colspan="6"></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import CommentInline from "../../../components/elements/CommentInline.vue";

export default {
  name: "FinancialTemplate",
  components: {
    CommentInline,
  },
  props: {
    sheet: Object,
    value: Array,
    referenceBudgets: Object,
    monthSelected: String,
  },
  data() {
    return {
      categories: [],
      referenceBudgetSelected: "Budget Référence",
      referenceBudgetSelectorActive: false,
      today: new Date(),
      columnsCollapsed: {
        budget: false,
        isf: false,
        oscar: false,
        breakdown: false,
      },
    };
  },
  mounted() {
    if (this.value) {
      this.categories = this.value;
    }

    Object.values(this.referenceBudgets).forEach((budget) => {
      if (
        typeof this.referenceBudgetSelected === "string" ||
        budget.referenceDate > this.referenceBudgetSelected.referenceDate
      ) {
        this.referenceBudgetSelected = budget;
      }
    });
  },
  watch: {
    value(val) {
      this.categories = val;
    },
  },
  computed: {
    ...mapGetters({
      isEditing: "App/isEdition",
    }),
    selectedBudgetAmounts() {
      let prestations = {
        total: 0,
      };

      if (!this.sheet || !this.sheet.categories) {
        return prestations;
      }

      if (typeof this.referenceBudgetSelected === "string") {
        this.categories.forEach((cat) => {
          cat.prestations.forEach((p) => {
            prestations[p.prestationId] = p.amount;
            if (!prestations[p.categoryName]) {
              prestations[p.categoryName] = 0;
            }
            prestations[p.categoryName] += p.amount;
            prestations.total += p.amount;
          });
        });
        return prestations;
      }

      this.referenceBudgetSelected.referenceBudgetPrestations.forEach((p) => {
        prestations[p.prestationId] = p.amount;
        if (!prestations[p.categoryName]) {
          prestations[p.categoryName] = 0;
        }
        prestations[p.categoryName] += p.amount;
        prestations.total += p.amount;
      });

      return prestations;
    },
    selectedIncomesAmounts() {
      let incomes = {
        total: 0,
      };
      if (!this.sheet || !this.sheet.categories) {
        return incomes;
      }

      if (typeof this.referenceBudgetSelected === "string") {
        this.sheet.categories.forEach((cat) => {
          incomes[cat.categoryName] = cat.incomes;
          incomes.total += cat.incomes;
        });
      } else {
        this.referenceBudgetSelected.referenceBudgetIncomes.forEach((inc) => {
          incomes[inc.categoryName] = inc.amount;
          incomes.total += inc.amount;
        });
      }

      return incomes;
    },
    cdpBudget() {
      if (!this.sheet.lastValidatedBudget) {
        return null;
      }
      const budgets = {
        0: null,
        1: "Aps",
        2: "Exe",
      };

      return budgets[this.sheet.lastValidatedBudget];
    },
    totalBudget() {
      let budget = this.sheet.totalBudget;
      if (this.monthSelected === "current") {
        budget += this.sheet.unaffectedPrestation
          ? this.sheet.unaffectedPrestation.currentExpenses
          : 0;
      }
      return budget;
    },
  },
  methods: {
    selectReferenceBudget(selection) {
      this.referenceBudgetSelected = selection;
      this.referenceBudgetSelectorActive = !this.referenceBudgetSelectorActive;
    },
    updateCapFnp(val, catIndex, pIndex) {
      if (val === "") val = 0;
      this.categories[catIndex].prestations[pIndex].capFnp = parseFloat(val);
      this.categories[catIndex].prestations[pIndex].amount =
        parseFloat(val) +
        this.categories[catIndex].prestations[pIndex].phaseExpenses +
        this.categories[catIndex].prestations[pIndex].rad;
      this.$emit("input", this.categories);
    },
    updateRad(val, catIndex, pIndex) {
      if (val === "") val = 0;
      this.categories[catIndex].prestations[pIndex].rad = parseFloat(val);
      this.categories[catIndex].prestations[pIndex].amount =
        parseFloat(val) +
        this.categories[catIndex].prestations[pIndex].phaseExpenses +
        this.categories[catIndex].prestations[pIndex].capFnp;
      this.$emit("input", this.categories);
    },
    updateProgress(val, catIndex, pIndex) {
      if (val === "") val = 0;
      this.categories[catIndex].prestations[pIndex].progress = parseFloat(val);
      this.$emit("input", this.categories);
    },
    openSapDetail(records, month = false) {
      let html =
        '<div style="max-height: 80vh; overflow: auto; padding-right: 20px;"><table class="table" style=""><thead style="position: sticky; top: 0; background: white;"><tr>';
      html += "<th>Document d'achat</th>";
      html += "<th>Elément d'OTP</th>";
      html += "<th>Type de pièce</th>";
      html += "<th>Désignation du tiers</th>";
      html += '<th class="has-text-centered">Période</th>';
      html += "<th>N° de pièce</th>";
      html += '<th class="has-text-centered">Ligne d\'écriture</th>';
      html += '<th class="has-text-centered">Excercice comptable</th>';
      html += '<th class="has-text-right">Val./devise périm.</th>';
      html += "<th>Désignation</th>";
      html += "<th>Nature comptable</th>";
      html += "<th>Texte d'en tête de pièce</th>";
      html += '<th class="has-text-centered">Date comptable</th>';
      html += "</tr></thead><tbody>";

      records.forEach((record) => {
        if (this.monthSelected === "complete") {
          // Record of the month
          if (month) {
            if (record.accountingYear != this.today.getFullYear()) {
              return;
            }
            if (
              this.sheet.project.doubleMonth &&
              record.period < this.today.getMonth() - 1
            ) {
              return;
            }
            if (
              !this.sheet.project.doubleMonth &&
              record.period != this.today.getMonth()
            ) {
              return;
            }
          }

          // If month after current month
          if (
            record.accountingYear == this.today.getFullYear() &&
            record.period === this.today.getMonth() + 1
          ) {
            return;
          }
        } else {
          if (month) {
            if (record.accountingYear != this.today.getFullYear()) {
              return;
            }
            if (
              this.sheet.project.doubleMonth &&
              record.period < this.today.getMonth()
            ) {
              return;
            }
            if (
              !this.sheet.project.doubleMonth &&
              record.period != this.today.getMonth() + 1
            ) {
              return;
            }
          }
        }

        html += "<tr>";
        html += "<td>" + record.purchaseDocument + "</td>";
        html += "<td>" + record.eotp + "</td>";
        html += "<td>" + record.partType + "</td>";
        html += "<td>" + record.tiersDesignation + "</td>";
        html += '<td class="has-text-centered">' + record.period + "</td>";
        html += "<td>" + record.partNumber + "</td>";
        html += '<td class="has-text-centered">' + record.lineNumber + "</td>";
        html +=
          '<td class="has-text-centered">' + record.accountingYear + "</td>";
        html +=
          '<td class="has-text-right">' +
          (record.amount &&
            record.amount.toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
            })) +
          "</td>";
        html += "<td>" + record.designation + "</td>";
        html += "<td>" + record.accountingNature + "</td>";
        html += "<td>" + record.partHeader + "</td>";
        html +=
          '<td class="has-text-centered">' + record.accountingDate + "</td>";
        html += "</tr>";
      });

      html += "</tbody></table></div>";
      this.$awn.modal(html);
    },
    openIsfDetail(records) {
      let html =
        '<div style="max-height: 80vh; overflow: auto; padding-right: 20px;"><table class="table" style=""><thead style="position: sticky; top: 0; background: white;"><tr>';
      html += "<th>Projet</th>";
      html += "<th>Marché sous-traitant</th>";
      html += "<th>Imputation</th>";
      html += "<th>Désignation eOTP</th>";
      html += "<th>Sous-projet</th>";
      html += "<th>Référence ARCGIS</th>";
      html += "<th>Référence de la commande</th>";
      html += "<th>Statut de la commande</th>";
      html += "<th>Catégorie</th>";
      html += "<th>Sous-catégorie</th>";
      html += "<th>Nature comptable</th>";
      // html += "<th>NRO</th>";
      // html += "<th>PM</th>";
      html += "<th>Référence</th>";
      html += "<th>Désignation</th>";
      html += "<th>Unité</th>";
      html += '<th class="has-text-right">Prix unitaire</th>';
      html += '<th class="has-text-right">Quantités commandées</th>';
      html += '<th class="has-text-right">Quantités réalisées</th>';
      html += '<th class="has-text-right">Montant commandé</th>';
      html += '<th class="has-text-right">Montant réalisé</th>';
      html += '<th class="has-text-right">Reste à facturer</th>';
      html += "</tr></thead><tbody>";

      records.forEach((record) => {
        html += "<tr>";
        html += "<td>" + record.project + "</td>";
        html += "<td>" + record.market + "</td>";
        html += "<td>" + record.imputation + "</td>";
        html += "<td>" + record.eotpDescription + "</td>";
        html += "<td>" + record.subprojectName + "</td>";
        html += "<td>" + record.arcgisReference + "</td>";
        html += "<td>" + record.osReference + "</td>";
        html += "<td>" + record.osStatus + "</td>";
        html += "<td>" + record.category + "</td>";
        html += "<td>" + record.subcategory + "</td>";
        html += "<td>" + record.accountingNature + "</td>";
        // html += "<td>" + record.nro + "</td>";
        // html += "<td>" + record.pm + "</td>";
        html += "<td>" + record.reference + "</td>";
        html += "<td>" + record.description + "</td>";
        html += "<td>" + record.unit + "</td>";
        html +=
          '<td class="has-text-right">' +
          (record.price &&
            record.price.toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
            })) +
          "</td>";
        html +=
          '<td class="has-text-right">' +
          (record.quantityOrdered &&
            record.quantityOrdered.toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
            })) +
          "</td>";
        html +=
          '<td class="has-text-right">' +
          (record.quantityDone &&
            record.quantityDone.toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
            })) +
          "</td>";
        html +=
          '<td class="has-text-right">' +
          (record.amountOrdered &&
            record.amountOrdered.toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
            })) +
          "</td>";
        html +=
          '<td class="has-text-right">' +
          (record.amountDone &&
            record.amountDone.toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
            })) +
          "</td>";
        html +=
          '<td class="has-text-right">' +
          (record.amountOrdered - record.amountDone).toLocaleString("fr-FR", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }) +
          "</td>";
        html += "</tr>";
      });

      html += "</tbody></table></div>";
      this.$awn.modal(html);
    },
    openBudgetPrestationDetail(records) {
      let html =
        '<div style="max-height: 80vh; overflow: auto; padding-right: 20px;"><table class="table" style=""><thead style="position: sticky; top: 0; background: white;"><tr>';
      html += "<th>Catégorie</th>";
      html += "<th>Sous-catégorie</th>";
      html += "<th>Prestation</th>";
      html += "<th>Sous-prestation</th>";
      html += "<th>Hypothèse</th>";
      html += "<th>Unité</th>";
      html += "<th>Prix unitaire</th>";
      html += "<th>Quantités retenues</th>";
      html += "<th>Coeff. CDP</th>";
      html += "<th>Montant</th>";
      html += "</tr></thead><tbody>";

      records.forEach((record) => {
        html += "<tr>";
        html += "<td>" + record.subPrestation.prestation.categoryName + "</td>";
        html +=
          "<td>" + record.subPrestation.prestation.subCategoryName + "</td>";
        html += "<td>" + record.subPrestation.prestation.name + "</td>";
        html += "<td>" + record.subPrestation.subPrestationName.name + "</td>";
        html +=
          "<td>" +
          (record.subPrestation.hypothesis * 100).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
          }) +
          "%</td>";
        html += "<td>" + record.subPrestation.oscarColumn.name + "</td>";
        html +=
          "<td>" +
          record.subPrestation.price.toLocaleString("fr-FR", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }) +
          "</td>";
        html +=
          "<td>" +
          record.quantity.toLocaleString("fr-FR", {
            minimumFractionDigits: 2,
          }) +
          "</td>";
        html +=
          "<td>" +
          (record.ratioCdp * 100).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
          }) +
          "%</td>";
        html +=
          "<td>" +
          record.totalAmount.toLocaleString("fr-FR", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }) +
          "</td>";
        html += "</tr>";
      });

      html += "</tbody></table></div>";
      this.$awn.modal(html);
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  display: block;
  white-space: initial;
  height: initial;
  border: 1px solid #fff;
  & > span {
    margin-right: 4px;
  }
  &:focus {
    box-shadow: initial;
  }
  &:hover {
    border-color: #eee;
  }
}
thead {
  position: sticky;
  top: 52px;
  z-index: 20;
}

th {
  text-align: center !important;
  border: none;
}
.inline-border {
  box-shadow: 0 -2px #dbdbdb;
}
.dropdown-menu {
  min-width: 50px;
}
.dropdown-item {
  cursor: pointer;
  padding: 6px 30px;
  &:hover {
    background-color: #eee;
  }
}

.clickable {
  cursor: pointer;
}

.sub-total {
  td {
    opacity: 0.9;
    border: none;
  }
}
.total {
  font-weight: bold;
}

.collapsed {
  display: block;
  width: 0;
  padding-right: 0;
  padding-left: 0;
  color: transparent !important;
  white-space: nowrap;
}
.collapsed-border {
  border-right: 2px solid #ccc;
}
.collapse-button {
  position: absolute;
  top: -35px;
  left: 0;
}
</style>
