<template>
  <div class="budget-edition-tab">
    <div class="tab-header">
      <div class="tab-title" :class="{'has-background-info': activeTab != 0, 'has-text-white': activeTab != 0, 'has-background-info-light': activeTab == 0, 'has-text-info': activeTab == 0, active: activeTab == 0}" @click="activeTab = 0"><span>EXE</span></div>
      <div class="tab-title" :class="{'has-background-info': activeTab != 1, 'has-text-white': activeTab != 1, 'has-background-info-light': activeTab == 1, 'has-text-info': activeTab == 1, active: activeTab == 1}" @click="activeTab = 1"><span>APS</span></div>
      <div class="tab-title" :class="{'has-background-warning': activeTab != 2, 'has-text-white': activeTab != 2, 'has-background-warning-light': activeTab == 2, 'has-text-warning': activeTab == 2, active: activeTab == 2}" @click="activeTab = 2"><span>En cours</span></div>
      <div class="tab-title" v-for="(referenceBudget, index) in savedBudgets" :key="'rb_' + index" :class="{'has-background-primary': activeTab != index + 3, 'has-text-white': activeTab != index + 3, 'has-background-primary-light': activeTab == index + 3, 'has-text-primary': activeTab == index + 3, active: activeTab == index + 3}" @click="activeTab = index + 3"><span>{{ referenceBudget.name }}</span></div>
    </div>
    <div class="tab-content">
      <Budget
        v-if="activeTab == 0"
        v-model="budgetExe"
        :sheet="sheet"
        :validateBudget="validateBudgetExe"
        :validationMessage="validationMessageExe"
        :alertLevel="budgetAps.validated ? 'info' : 'warning'"
        :invalidateBudget="invalidateBudgetExe"
      />
      <Budget
        v-if="activeTab == 1"
        v-model="budgetAps"
        :sheet="sheet"
        :validateBudget="validateBudgetAps"
        :validationMessage="validationMessageAps"
        alertLevel="info"
        :invalidateBudget="budgetExe.validated ? null :invalidateBudgetAps"
      />
      <ReferenceBudget v-if="activeTab == 2" :budget="currentBudget" @record="fetchData" />
      <ReferenceBudget v-if="activeTab > 2" :budget="savedBudgets[activeTab - 3]" @record="fetchData" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios'
import ReferenceBudget from '../../../components/elements/ReferenceBudget'
import Budget from '../../../components/elements/Budget'

export default {
  name: 'budget-tab',
  components: {
    ReferenceBudget,
    Budget
  },
  props: ['sheet'],
  data() {
    return {
      budgetAps: {},
      budgetExe: {},
      referenceBudgets: [],
      activeTab: 0,
      validationMessageAps: 'Voulez-vous valider le budget APS ?',
    }
  },
  computed: {
    ...mapGetters({
    }),
    currentBudget() {
      if(this.referenceBudgets.length < 1) {
        return {id: 'pas de prestations'}
      }
      return this.referenceBudgets.find(rb => rb.milestone === null)
    },
    savedBudgets() {
      let budgets = []

      this.referenceBudgets.forEach((budget) => {
        if(budget.milestone) budgets.push(budget)
      })

      return budgets
    },
    validationMessageExe() {
      return this.budgetAps.validated ? 'Voulez-vous valider le budget EXE ?' : 'Attention le budget APS n\'est pas validé. En continuant vous validerez les deux budgets APS et EXE. Voulez-vous continuer ?'
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    sheet() {
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      if(!this.sheet.id)
        return;

      this.$Api.ReferenceBudget.fetchReferenceBudgetsForSheet.bind(this, this.sheet.id)(data => {
        this.referenceBudgets = data
      })
      this.$Api.Sheet.fetchBudgets(this.sheet.id, (data) => {
        data.forEach(budget => {
          if(budget.phase.name === 'APS') {
            this.budgetAps = budget
          }
          if(budget.phase.name === 'EXE') {
            this.budgetExe = budget
          }
        })
      })
    },
    validateBudgetAps() {
      return axios.put(`/budgets/${this.budgetAps.id}/validate`).then((response) => {
        if (response.data.success) {
          this.budgetAps.validated = true
        }
      });
    },
    validateBudgetExe() {
      if(this.budgetAps.validated) {
        return axios.put(`/budgets/${this.budgetExe.id}/validate`).then((response) => {
          if (response.data.success) {
            this.budgetExe.validated = true
          }
        });
      } else {
        return axios.put(`/budgets/${this.budgetAps.id}/validate`).then((response) => {
          if (response.data.success) {
            this.budgetAps.validated = true
            axios.put(`/budgets/${this.budgetExe.id}/validate`).then((response) => {
              if (response.data.success) {
                this.budgetExe.validated = true
              }
            });
          }
        });
      }
    },
    invalidateBudgetAps() {
      return axios.put(`/budgets/${this.budgetAps.id}/invalidate`).then((res) => {
        if (res.data.success) {
          this.budgetAps.validated = false
        }
      })
    },
    invalidateBudgetExe() {
      return axios.put(`/budgets/${this.budgetExe.id}/invalidate`).then((res) => {
        if (res.data.success) {
          this.budgetExe.validated = false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.budget-edition-tab {
  display: flex;
  background-color: #fff;
  padding: 0.5em 0.75em;
}
.tab-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 50px;
  .tab-title {
    background-color: var(--axione-dark-grey);
    font-weight: bold;
    padding: 10px 80px 10px 20px;
    cursor: pointer;
    border-top: 1px solid white;
    &.active.has-text-info {
      box-shadow: 3px 0px var(--axione-blue) inset;
    }
    &.active.has-text-warning {
      box-shadow: 3px 0px var(--axione-orange) inset;
    }
    &.active.has-text-primary {
      box-shadow: 3px 0px var(--axione-dark-grey) inset;
    }
  }
}
.tab-content {
  flex: 1;
  padding: 0 25px;
}

</style>
