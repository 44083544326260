<template>
  <div class="comment-button is-inline-block">
    <div
      class="button is-small is-info"
      :class="{
        'is-light': !commentData,
        'is-visible': !!commentData,
      }"
      @click="open"
    >
      <font-awesome-icon icon="comment" />
    </div>

    <b-tooltip
      ref="tooltip"
      position="is-right"
      style="top: -15px"
      type="is-light"
      :autoClose="['outside', 'escape']"
      contentClass="p-2 w-max"
      :appendToBody="false"
    >
      <template v-slot:content>
        <div class="comment-form">
          <template v-if="!commentData">
            <CommentForm
              @cancel="close()"
              @added="refresh"
              :sheetId="sheet.id"
              :prestationId="type === 'prestation' ? item.id : null"
              :categoryId="type === 'category' ? item.id : null"
            />
          </template>
          <template v-else>
            <div v-if="!commentData.action" class="p-3">
              <div class="min-w-500 is-primary-light">
                {{ commentData.createdAt | date("DD/MM/YYYY HH:mm") }} -
                <strong>{{ commentData.creator.name }}</strong>
              </div>
              <Comment :comment="commentData" @deleted="refresh" />
            </div>
            <Discussion
              v-else
              :comment="commentData"
              @deleted="refresh"
              @close="refresh"
            />
          </template>
        </div>
      </template>
    </b-tooltip>
  </div>
</template>

<script>
import CommentForm from "./CommentForm.vue";
import Discussion from "./Discussion.vue";
import Comment from "./Comment.vue";

export default {
  name: "CommentInline",
  components: {
    CommentForm,
    Discussion,
    Comment,
  },
  props: {
    sheet: {},
    item: {},
    type: String,
  },
  data() {
    return {
      commentData: null,
    };
  },

  mounted() {
    if (this.item.comment) {
      this.commentData = this.item.comment;
    }
  },

  methods: {
    refresh() {
      // Fetch the comment for the current prestation/category of the current sheet.
      this.axios
        .get(`/sheets/${this.sheet.id}/comment/${this.type}/${this.item.id}`)
        .then((response) => {
          this.commentData = response.data;
        });
    },
    open() {
      setTimeout(() => {
        this.$refs.tooltip.open();
      }, 50);
    },
    close() {
      // Tiny hack because the "close" function in buefy is buggy.
      this.$refs.tooltip.clickedOutside({ target: "" });
    },
  },
};
</script>

<style scoped>
.comment-button {
  position: absolute;
  top: 5px;
  right: -25px;
}

.button {
  opacity: 0;
  transition-duration: 0.5s;
}

.is-visible {
  opacity: 1;
}

tr:hover .button {
  opacity: 1;
}
.comment-is-visible {
  opacity: 1;
}
</style>
