<template>
  <div
    class="event"
    :cy-data="'event-' + event.type"
    v-if="filters.includes(event.type)"
  >
    <div
      class="icon"
      :class="{
        'has-background-success': event.type === 'budget-control',
        'has-background-warning': event.type === 'sheet-state',
        'has-background-primary': event.type === 'update',
        'has-background-info': event.type === 'comment',
      }"
    >
      <font-awesome-icon v-if="event.type === 'comment'" icon="comment" />
      <font-awesome-icon v-if="event.type === 'update'" icon="info" />
      <font-awesome-icon
        v-if="event.type === 'sheet-state'"
        icon="exchange-alt"
      />
      <font-awesome-icon
        v-if="event.type === 'budget-control'"
        icon="calendar-check"
      />
    </div>
    <div class="content">
      <div class="is-primary-light">
        {{ event.createdAt | date("DD/MM/YYYY HH:mm") }} -
        <strong>{{ event.creator.name }}</strong>
        <span class="event-action" v-html="event.comment"></span>
        <span
          v-if="event.entity && event.entity.referenceBudgetPrestation"
          class="event-action"
          >sur la prestation
          <strong class="has-text-info">{{
            event.entity.referenceBudgetPrestation.prestationName
          }}</strong></span
        >
        <span v-if="event.entity && event.entity.category" class="event-action"
          >sur la recette
          <strong class="has-text-info">{{
            event.entity.category.name
          }}</strong></span
        >
      </div>
      <Comment
        v-if="event.type === 'comment'"
        :comment="event.entity"
        @deleted="$emit('deleted')"
      />
      <div v-if="event.type === 'budget-control' && event.entity.comment">
        <p class="is-size-7 mb-0">
          <strong>{{ event.entity.sheet.owner.name }}</strong> a commenté :
        </p>
        <div class="has-background-white p-2">{{ event.entity.comment }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Comment from "../elements/Comment.vue";
export default {
  components: { Comment },
  name: "event",
  props: {
    event: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.event {
  position: relative;
  margin: 1em 0;
  &:last-child {
    margin-bottom: 0;
  }
  .icon {
    position: absolute;
    top: 7px;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid #f1f1f1;
    text-align: center;
    // background-color: #1ab394 !important;
    color: #ffffff;
  }
  .content {
    position: relative;
    margin-left: 60px;
    background: white;
    border-radius: 0.25em;
    padding: 1em;
    background: #f5f5f5;
    &::before {
      content: "";
      position: absolute;
      top: 20px;
      right: 100%;
      height: 0;
      width: 0;
      border: 7px solid transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      border-right-style: solid;
      border-right-width: 7px;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right: 7px solid white;
      border-right-color: white;
      border-color: transparent #f5f5f5 transparent transparent;
    }
    & > * {
      margin-bottom: 10px;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
  }
}
.event-action {
  margin-left: 5px;
}
</style>
