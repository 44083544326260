<template>
  <div class="comments-tab panel-block">
    <div class="left-content">
      <div class="actions">
        <div class="field has-addons">
          <p class="control" title="Commentaires">
            <button class="button" :class="filters.includes('comment') ? 'is-info' : 'is-info is-light'" @click="toggleFilter('comment')">
              <span class="icon is-small">
                <font-awesome-icon icon="comment" />
              </span>
            </button>
          </p>
          <p class="control" title="Mises à jour">
            <button class="button" :class="filters.includes('update') ? 'is-primary' : 'is-primary is-light'" @click="toggleFilter('update')">
              <span class="icon is-small">
                <font-awesome-icon icon="info" />
              </span>
            </button>
          </p>
          <p class="control" title="Changement d'état">
            <button class="button" :class="filters.includes('sheet-state') ? 'is-warning' : 'is-warning is-light'" @click="toggleFilter('sheet-state')">
              <span class="icon is-small">
                <font-awesome-icon icon="exchange-alt" />
              </span>
            </button>
          </p>
          <p class="control" title="Historisations">
            <button class="button" :class="filters.includes('budget-control') ? 'is-success' : 'is-success is-light'" @click="toggleFilter('budget-control')">
              <span class="icon is-small">
                <font-awesome-icon icon="calendar-check" />
              </span>
            </button>
          </p>
        </div>
        <button class="button is-warning" v-if="!addCommentForm" @click="toggleAddCommentForm"> + commentaire</button>
      </div>

      <CommentForm v-if="addCommentForm" :sheetId="id" @cancel="toggleAddCommentForm" @added="commented"/>

      <div class="timeline">
        <Event :event="event" v-for="(event, index) in events" :key="'e_' +index" :filters="filters" @deleted="initVal" @edit="initVal"/>
      </div>
    </div>
    <div class="right-content">
      <div class="subtitle is-4 has-text-info">Discussions actives</div>
      <div class="discussions">
      <Discussion v-for="(comment, index) in activeDiscussions" :key="index" :comment="comment" @deleted="initVal" @close="initVal"/>
      </div>
    </div>
  </div>
</template>

<script>
import Event from '../../../components/elements/Event.vue';
import { mapGetters } from 'vuex';
import CommentForm from '../../../components/elements/CommentForm.vue';
import Discussion from '../../../components/elements/Discussion.vue'

export default {
  name: 'comments-tab',
  components: {
    Event,
    CommentForm,
    Discussion
  },
  props: ['id'],
  data() {
    return {
      events: [],
      filters: ['comment', 'update', 'sheet-state', 'budget-control'],
      addCommentForm: false,
      activeDiscussions: []
    };
  },
  computed: {
    ...mapGetters({
      authToken: 'Auth/getToken'
    }),
  },
  mounted() {
    this.initVal()
  },
  watch: {
    id() {
      this.initVal()
    }
  },
  methods: {
    initVal() {
      this.$Api.Sheet.fetchEventsForSheet.bind(this, this.id)(data => {
        this.events = data

        this.activeDiscussions = []
        data.forEach(event => {
          if(event.type === 'comment' && event.entity.active) {
            this.activeDiscussions.push(event.entity)
          }

        })
        this.activeDiscussions.reverse()
      })
    },
    toggleFilter(filter) {
      if(!this.filters.includes(filter)) {
        this.filters.push(filter)
        return
      }
      const idx = this.filters.findIndex(f => f === filter)
      this.filters.splice(idx, 1)
    },
    toggleAddCommentForm() {
      this.addCommentForm = !this.addCommentForm
    },
    commented() {
      this.initVal()
      this.toggleAddCommentForm()
    }
  }
}
</script>

<style scoped lang="scss">
.comments-tab {
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
}

.button {
  min-width: 50px;
}
.left-content, .right-content {
  width: 50%;
  padding-left: 30px;
  padding-right: 30px;
}
.timeline {
  width: 100%;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 45px;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
}
</style>
