<template>
    <div class="reference-budget">
      <div class="actions">
        <div class="import-fields">
          <selector-field
            :options="$Api.ReferenceBudget.fetchAvailableMilestonesForSheet.bind(this, budget.sheet.id)"
            v-model="milestone"
            label="Nom du budget"
            :inline="false"
            :edit="true"
            v-if="importMode"
          />
          <date-field
            v-model="newBudget.referenceDate"
            label="Date"
            :inline="false"
            :edit="true"
            v-if="importMode"
          />
        </div>
        <button class="button is-warning" v-if="options.includes($UserOptions.BUDGETS.VALIDATE) && !editMode && budget.milestone && sheetState !== $States.VALIDATED" @click="editMode = !editMode">Modifier</button>
        <button class="button is-warning" v-if="milestoneAvailable && !budget.milestone && !importMode && sheetState !== $States.VALIDATED" @click="importMode = !importMode">Importer</button>
        <button class="button is-warning" v-if="milestoneAvailable && !budget.milestone && !importMode && sheetState !== $States.VALIDATED" @click="openModal">Historiser</button>
        <button class="button is-warning" v-if="importMode || editMode" @click="cancel">Annuler</button>
        <button class="button is-warning" v-if="importMode" @click="importBudget">Sauvegarder</button>
        <button class="button is-warning" v-if="editMode" @click="updateBudget">Sauvegarder</button>
      </div>
      <div v-if="!importMode && !editMode">
        <div class="title-total">
          <h3 class="title is-4 has-text-warning">Recettes</h3>
          <span class="line"></span>
          <h3 class="title is-5 has-text-warning">{{ totalIncomes | priceEUR }}</h3>
        </div>
        <table class="table" style="width: 100%">
          <thead>
            <tr>
              <th>Catégorie</th>
              <th class="has-text-right">Montant</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(income, index) in budget.referenceBudgetIncomes" :key="'i_' + index">
              <td>{{ income.category.name }}</td>
              <td class="has-text-right">{{ income.amount | priceEUR }}</td>
            </tr>
          </tbody>
        </table>

        <div class="title-total">
          <h3 class="title is-4 has-text-warning">Dépenses</h3>
          <span class="line"></span>
          <h3 class="title is-5 has-text-warning">{{ totalAmount | priceEUR }}</h3>
        </div>
        <table class="table" style="width: 100%">
          <thead class="sticky">
            <tr>
              <th>Catégorie</th>
              <th>Sous-catégorie</th>
              <th>Prestation</th>
              <th class="has-text-right">Montant</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(prestation, index) in budget.referenceBudgetPrestations" :key="index">
              <td>{{ prestation.categoryName }}</td>
              <td>{{ prestation.subCategoryName }}</td>
              <td>{{ prestation.prestationName }}</td>
              <td class="has-text-right">{{ prestation.amount | priceEUR }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <ImportReferenceBudget v-if="importMode" v-model="importItems" :columns="handsonColumns" />
      <ImportReferenceBudget v-if="editMode" v-model="importItems" :columns="handsonColumns" />

      <modal ref="selectMilestoneModal" @create="saveReferenceBudget" title="Séléction du nom du budget">
      <div class="columns">
        <div class="column is-half">
          <selector-field
            :options="$Api.ReferenceBudget.fetchAvailableMilestonesForSheet.bind(this, budget.sheet.id)"
            label="Nom du budget"
            v-model="milestone"
            :inline="false"
            :edit="true"
          ></selector-field>
        </div>
      </div>
    </modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from "dayjs";
import axios from 'axios'
import ImportReferenceBudget from '../../views/imports/ReferenceBudget.vue'
import DateField from '../fields/DateField.vue'

export default {
  name: 'reference-budget',
  components: {
    ImportReferenceBudget,
    DateField
  },
  props: {
    budget: {}
  },
  data() {
    return {
      customMilestone: false,
      milestone: '',
      importMode: false,
      editMode: false,
      newBudget:{
        referenceDate: dayjs(Date.now()).format("YYYY-MM-DD")
      },
      handsonColumns: [
        {
          label: 'Type',
          key: 'type',
          readOnly: true
        },
        {
          label: 'Catégorie',
          key: 'category',
          readOnly: true
        },
        {
          label: 'Sous-catégorie',
          key: 'subCategory',
          readOnly: true
        },
        {
          label: 'Prestation',
          key: 'prestation',
          readOnly: true
        },
        {
          label: 'Montant',
          key: 'amount',
          type: 'numeric'
        },
      ],
      milestoneAvailable: false
    }
  },
  computed: {
    ...mapGetters({
      options: 'Auth/getOptions'
    }),
    totalAmount() {
      if(!this.budget.referenceBudgetPrestations) return 0

      return this.budget.referenceBudgetPrestations.reduce((acc, val) => acc + (+val.amount || 0), 0)
    },
    totalIncomes() {
      if(!this.budget.referenceBudgetIncomes) return 0

      return this.budget.referenceBudgetIncomes.reduce((acc, val) => acc + (+val.amount || 0), 0)
    },
    importItems: {
      get() {
        let items = []
        Object.values(this.newBudget.referenceBudgetIncomes).sort((a, b) => {
          if(a.categoryName < b.categoryName) return -1
          return 1
        }).forEach(rbi => {
          const income = {
            id: rbi.id,
            type: 'Recette',
            category: rbi.category.name,
            subCategory: '',
            prestation: '',
            amount: this.budget.milestone ? rbi.amount : 0
          }
          items.push(income)
        });
        Object.values(this.newBudget.referenceBudgetPrestations).sort((a, b) => {
          if(a.categoryName < b.categoryName || 
            a.categoryName == b.categoryName && a.subCategoryName < b.subCategoryName || 
            a.categoryName == b.categoryName && a.subCategoryName == b.subCategoryName &&a.prestationName < b.prestationName
          ) return -1
          return 1
        }).forEach(rbp => {
          const expense = {
            id: rbp.id,
            type: 'Dépense',
            category: rbp.categoryName,
            subCategory: rbp.subCategoryName,
            prestation: rbp.prestationName,
            amount: this.budget.milestone ? rbp.amount : 0
          }
          items.push(expense)
        })
        return items
      },
      set(val) {
        val.forEach(item => {
          if(item.type === 'Recette') {
            this.newBudget.referenceBudgetIncomes[item.id].amount = item.amount
          }
          if(item.type === 'Dépense') {
            this.newBudget.referenceBudgetPrestations[item.id].amount = item.amount
          }
        })
      }
    },
    sheetState() {
      if(!this.budget.sheet) return ''

      return this.budget.sheet.state.code
    }
  },
  mounted() {
    if(!this.budget) {
      return
    }

    this.initNewBudget()
  },
  watch: {
    budget() {
      this.cancel()
      // this.initNewBudget()
    },
  },
  methods: {
    initNewBudget() {
      this.$set(this.newBudget, 'referenceBudgetIncomes', JSON.parse(JSON.stringify(this.budget.referenceBudgetIncomes.reduce((obj, rbi) => (obj[rbi.id] = rbi, obj), {}))))
      this.$set(this.newBudget, 'referenceBudgetPrestations', JSON.parse(JSON.stringify(this.budget.referenceBudgetPrestations.reduce((obj, rbp) => (obj[rbp.id] = rbp, obj), {}))))

      if(!this.budget.milestone) {
        this.$Api.ReferenceBudget.fetchAvailableMilestonesForSheet(this.budget.sheet.id, (res) => {
          this.milestoneAvailable = res.length !== 0
        })
      }
    },
    openModal() {
      this.$refs.selectMilestoneModal.open();
    },
    saveReferenceBudget() {
      let budget = { ...this.budget }
      budget.name = this.milestone

      return axios.post(`/reference-budgets/${this.budget.id}/record`, budget).then((response) => {
        if (response.data.success) {
          this.$emit('record')
          this.$refs.selectMilestoneModal.close();
        }
      });
    },
    cancel() {
      this.importMode = false
      this.editMode = false
      this.initNewBudget()
    },
    importBudget() {
      let budget = { ...this.budget }
      budget.name = this.milestone
      budget.referenceDate = this.newBudget.referenceDate
      budget.referenceBudgetIncomes = Object.values(this.newBudget.referenceBudgetIncomes)
      budget.referenceBudgetPrestations = Object.values(this.newBudget.referenceBudgetPrestations)
      
      return axios.post(`/reference-budgets/import`, budget).then((response) => {
        if (response.data.success) {
          this.$emit('record')
          this.importMode = false
        }
      });
    },
    updateBudget() {
      let budget = { ...this.budget }
      budget.referenceBudgetIncomes = Object.values(this.newBudget.referenceBudgetIncomes)
      budget.referenceBudgetPrestations = Object.values(this.newBudget.referenceBudgetPrestations)
      
      return axios.put(`/reference-budgets/${this.budget.id}`, budget).then((response) => {
        if (response.data.success) {
          this.$emit('record')
          this.cancel()
        }
      });
    },
  }
    
}
</script>

<style scoped lang="scss">
.button {
  margin-left: 4px;
}
.reference-budget {
  width: 100%;
}
.sticky {
  position: sticky;
  top: 52px;
  background: white;
}
.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.import-fields {
  display: flex;
  justify-content: flex-start;
  margin-right: auto;
}
.title-total{
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
  >* {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
  }
  .line {
    flex: 1;
    border-bottom: 1px dotted $axione-orange;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>