<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.Sheet"
        :edit="
          (currentTab == 0 ||
            currentTab == 2 ||
            (currentTab == 5 &&
              options.includes($UserOptions.FORECASTS.EDIT)) ||
            currentTab == 6) &&
          !isEditing &&
          sheetState !== $States.VALIDATED
            ? edit
            : null
        "
        :save="
          (currentTab == 0 ||
            currentTab == 2 ||
            currentTab == 5 ||
            currentTab == 6) &&
          isEditing
            ? save
            : null
        "
        :cancel="
          (currentTab == 0 ||
            currentTab == 2 ||
            currentTab == 5 ||
            currentTab == 6) &&
          isEditing
            ? cancel
            : null
        "
        :remove="sheet.locked ? null : remove"
      >
        <h1 class="title" data-test="sel-sheet-header">
          <breadcrumb location="/sheets" text="Fiche Affaire"></breadcrumb>
          <button
            class="button is-success is-small ml-2"
            :disabled="!previousSheetId"
            @click="$router.push('/sheet/' + previousSheetId)"
          >
            <font-awesome-icon icon="chevron-left" />
          </button>
          {{ sheet.name }}
          <button
            class="button is-success is-small mr-2"
            :disabled="!nextSheetId"
            @click="$router.push('/sheet/' + nextSheetId)"
          >
            <font-awesome-icon icon="chevron-right" />
          </button>
          <span
            class="tag"
            :cy-data="'current-state'"
            :class="sheet.state && $Colors[sheetState]"
            >{{ sheet.state && sheet.state.name }}</span
          >
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab == 0 }"
                @click="changeTab(0)"
              >
                <a>Contrôle budgétaire</a>
              </li>
              <li
                :class="{ 'is-active': currentTab == 1 }"
                @click="changeTab(1)"
              >
                <a>Budget</a>
              </li>
              <li
                :class="{ 'is-active': currentTab == 2 }"
                @click="changeTab(2)"
              >
                <a>Recettes</a>
              </li>
              <li
                :class="{ 'is-active': currentTab == 3 }"
                @click="changeTab(3)"
              >
                <a>Commentaires</a>
              </li>
              <li
                :class="{ 'is-active': currentTab == 4 }"
                @click="changeTab(4)"
              >
                <a>Evolutions FDC</a>
              </li>
              <li
                :class="{ 'is-active': currentTab == 5 }"
                @click="changeTab(5)"
              >
                <a>Prévisions</a>
              </li>
              <li
                :class="{ 'is-active': currentTab == 6 }"
                @click="changeTab(6)"
              >
                <a>Informations</a>
              </li>
            </ul>
          </div>
        </div>
        <ValidationObserver ref="form">
          <BudgetControlTab
            ref="budgetControlTab"
            :id="id"
            :sheetState="sheetState"
            v-if="currentTab == 0"
            @state="fetchData"
            @historized="fetchData()"
          />

          <BudgetTab :sheet="sheet" v-if="currentTab == 1" />

          <RevenuesTab
            ref="revenuesTab"
            :id="id"
            :imputations="sheet && sheet.imputations"
            @refresh="fetchData"
            v-if="currentTab == 2"
          />

          <CommentsTab :id="id" v-if="currentTab == 3" />

          <EvolutionsTab :id="sheet.id" v-if="currentTab == 4" />

          <ForecastTab
            ref="forecastTab"
            v-model="sheet"
            @refresh="fetchData"
            v-if="currentTab == 5"
          />

          <InformationTab
            v-model="sheet"
            @refresh="fetchData"
            v-if="currentTab == 6"
          />
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import InformationTab from "./tabs/InformationTab";
import BudgetControlTab from "./tabs/BudgetControlTab";
import BudgetTab from "./tabs/BudgetTab";
import { mapGetters } from "vuex";
import EvolutionsTab from "./tabs/EvolutionsTab.vue";
import RevenuesTab from "./tabs/RevenuesTab.vue";
import CommentsTab from "./tabs/CommentsTab.vue";
import ForecastTab from "./tabs/Forecast.vue";

export default {
  name: "sheet",
  components: {
    InformationTab,
    BudgetControlTab,
    BudgetTab,
    EvolutionsTab,
    RevenuesTab,
    CommentsTab,
    ForecastTab,
  },
  props: ["id"],
  computed: {
    ...mapGetters({
      isEditing: "App/isEdition",
      options: "Auth/getOptions",
      getFilters: "Filters/getFilters",
    }),
    sheetState() {
      return this.sheet.state ? this.sheet.state.code : "draft";
    },
    pagination() {
      let sort = {
        column: "id",
        direction: "ASC",
      };
      let filters = [];
      const entityFilters = this.getFilters(this.$EntitiesName.Sheet);
      if (entityFilters) {
        sort = entityFilters.sort;
        filters = entityFilters.filters.reduce((filters, column) => {
          filters[column.id] = column.filter;
          return filters;
        }, {});
      }

      let pagination = {
        page: 1,
        total: 0,
        size: 1,
        sort: sort,
        filters: filters,
      };

      return pagination;
    },
  },
  data() {
    return {
      currentTab: 0,
      sheet: {},
      oldEndDate: null,
      radIsEditing: false,
      previousSheetId: null,
      nextSheetId: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    changeTab(idx) {
      if (this.isEditing) {
        this.$awn.confirm(
          "Vous êtes en mode édition. En continuant vous perdrez tous les changements effectués, voulez-vous continuer ?",
          () => {
            this.$store.dispatch("App/setEditing", false);
            this.currentTab = idx;
          }
        );
        return;
      }
      this.currentTab = idx;
    },
    fetchData() {
      axios.get(`/sheets/${this.id}`).then((response) => {
        this.sheet = response.data
        this.oldEndDate = response.data.endDate
      });
      this.$Api.Sheet.fetchPreviousNextSheets(this.id, this.pagination).then(
        (res) => {
          this.previousSheetId = res.previousId;
          this.nextSheetId = res.nextId;
        }
      );
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        if (this.currentTab === 6) {
          console.log('old', this.oldEndDate)
          console.log('new', this.sheet.endDate)
          const endDateOld = new Date(this.oldEndDate);
          const endDateNew = new Date(this.sheet.endDate);
          if(endDateNew < endDateOld){
            const year = endDateNew.getFullYear().toString().padStart(4, '0')
            const month = (endDateNew.getMonth() + 1).toString()
            // alert user that saved forecasts will be deleted, if any
            // todo: check api to know if there is forecasts or not
            this.$awn.confirm(
              "Vous avez modifié la date de fin, les prévisions enregistrées seront supprimées, affichant à la place les prévisions par défaut. Procéder?",
              () => {
                this.deleteSheetForecasts(year, month).then(
                  ()=>{
                    this.saveInformation();
                  }
                )
              }
            )
          } else {
          console.log(222)
          this.saveInformation();
        }
        }

        if (this.currentTab === 0) {
          this.$refs.budgetControlTab.save();
        }
        if (this.currentTab === 2) {
          this.$refs.revenuesTab.save();
        }
        if (this.currentTab === 5) {
          this.$refs.forecastTab.save();
        }
      });
    },
    saveInformation() {
      const sheet = {
        ...this.sheet,
      };

      if (sheet.oscarSubProjectName) {
        sheet.oscarSubProjectName.sheet = { id: sheet.id };
      }

      return axios.put(`/sheets/${this.id}`, sheet).then((response) => {
        if (response.data.success) {
          this.fetchData();
          this.$store.dispatch("App/setEditing", false);
        }
      });
    },
    edit() {
      this.$store.dispatch("App/setEditing", true);
    },
    cancel() {
      if (this.currentTab === 6) {
        this.fetchData();
        this.$store.dispatch("App/setEditing", false);
      }
      if (this.currentTab === 0) {
        this.$refs.budgetControlTab.cancel();
      }
      if (this.currentTab === 2) {
        this.$refs.revenuesTab.cancel();
      }
      if (this.currentTab === 5) {
        this.fetchData();
        this.$store.dispatch("App/setEditing", false);
      }
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/sheets/${this.id}`).then((res) => {
            if (res.data.success) {
              this.$router.replace("/sheets");
            }
          })
      );
    },
    getYearMonth(date) {
      return date.substring(0, 7);
    },
    deleteSheetForecasts(year, month){
      return axios.delete(`/forecasts/sheet/${this.id}/year/${year}/month/${month}`)
    }
  },
};
</script>

<style lang="scss" scoped>
.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}
.container .header button {
  vertical-align: middle;
}
</style>
