<template>
  <div class="evolutions-tab panel-block column">
    <div class="actions">
      <button
        @click.stop="openOrderModal"
        slot="activator"
        class="button is-warning"
        v-if="!isEditing"
      >
        Ajouter un TS
      </button>
    </div>

    <div class="panel-block">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Recettes</th>
            <th>N° BDC</th>
            <th class="has-text-right">
              <div
                class="dropdown"
                :class="{ 'is-active': referenceBudgetSelectorActive }"
              >
                <div class="dropdown-trigger">
                  <button
                    class="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    @click="
                      referenceBudgetSelectorActive =
                        !referenceBudgetSelectorActive
                    "
                  >
                    <div>
                      <b>{{
                        referenceBudgetSelected.milestone
                          ? referenceBudgetSelected.name
                          : referenceBudgetSelected
                      }}</b>
                    </div>
                    <font-awesome-icon icon="angle-down" />
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                  <div class="dropdown-content">
                    <div
                      v-for="(referenceBudget, key) in referenceBudgets"
                      :key="key"
                      class="dropdown-item"
                      @click="selectReferenceBudget(referenceBudget)"
                    >
                      {{ referenceBudget.name }}
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th class="has-text-right">Recettes APS/EXE</th>
            <th class="has-text-right">Reprévisions FDC</th>
            <th class="has-text-right">TS / Devis émis</th>
            <th class="has-text-right">BDC reçus</th>
            <th class="has-text-right">Facturé</th>
            <th class="has-text-right">Reste à facturer</th>
            <th class="has-text-centered">% avancement affaire</th>
            <th class="has-text-right">Activité déclarée</th>
            <th class="has-text-right">FAE (+) / PCA (-)</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(category, categoryName) in categories">
            <template v-for="(order, index) in category.orders">
              <tr
                :key="categoryName + '_' + index"
                :class="{ 'has-background-danger-light': !order.ordered }"
              >
                <td
                  class="is-relative"
                  v-if="category.id"
                >
                  <template v-if="index === 0">
                    <span :cy-data="'categoryName'">{{ categoryName }}</span>
                    <CommentInline
                      :sheet="sheet"
                      :item="category"
                      type="category"
                      style="right: 0"
                    />
                  </template>
                </td>

                <td>{{ order.orderNumber }}</td>
                <td class="" colspan="3"></td>
                <td class="has-text-right" :cy-data="'amountTs'">
                  {{ order.ordered ? 0 : order.amount | number }}
                </td>
                <td class="has-text-right" :cy-data="'amountOrders'">
                  {{ order.ordered ? order.amount : 0 | number }}
                </td>
                <td class="has-text-right" :cy-data="'invoicedOrders'">
                  {{ order.invoiced | number }}
                </td>
                <td class="has-text-right" :cy-data="'invoiceLeft'">
                  {{
                    order.ordered ? order.amount - order.invoiced : 0 | number
                  }}
                </td>
                <td class="" colspan="3"></td>
              </tr>
            </template>
            <tr class="sub-total" :key="'i_' + categoryName + '_' + count">
              <td class="is-info" colspan="2" :cy-data="'categoryName'">
                Sous-Total {{ categoryName }}
              </td>
              <td
                class="is-info has-text-right"
                :cy-data="'referenceBudgetIncome'"
              >
                {{
                  selectedIncomesAmounts[categoryName]
                    ? selectedIncomesAmounts[categoryName]
                    : 0 | number
                }}
              </td>
              <td
                class="is-info has-text-right"
                :cy-data="'validatedBudgetIncome'"
              >
                {{ validatedBudget ? category[validatedBudget] : 0 | number }}
              </td>
              <td
                class="is-info"
                v-if="category.categoryId"
                :cy-data="'budgetIncome'"
              >
                <text-field
                  type="number"
                  :value="category.incomes"
                  @input="updateAmount($event, category.categoryId)"
                  :edit="isEditing"
                />
              </td>
              <td class="is-info has-text-right" v-else>0</td>
              <td class="is-info has-text-right" :cy-data="'amountTs'">
                {{ category.amountTs | number }}
              </td>
              <td class="is-info has-text-right" :cy-data="'amountOrders'">
                {{ category.amountOrders | number }}
              </td>
              <td class="is-info has-text-right" :cy-data="'invoicedOrders'">
                {{ category.invoicedOrders | number }}
              </td>
              <td class="is-info has-text-right" :cy-data="'invoiceLeft'">
                {{ (category.amountOrders - category.invoicedOrders) | number }}
              </td>

              <!-- Activity = 1, Real progress -->
              <td
                class="is-info has-text-centered"
                v-if="activityType == 1"
                :cy-data="'progress'"
              >
                {{ category.progress | percentage }}
              </td>
              <td
                class="is-info has-text-right"
                v-if="activityType == 1"
                :cy-data="'activity'"
              >
                {{ category.declaredActivity | number }}
              </td>
              <td
                class="is-info has-text-right"
                v-if="activityType == 1"
                :cy-data="'faepca'"
              >
                {{
                  (category.progress * category.amountOrders -
                    category.invoicedOrders)
                    | number
                }}
              </td>

              <!-- Activity = 2, Financial progress -->
              <td
                class="is-info has-text-centered"
                v-if="activityType == 2"
                :cy-data="'progress'"
              >
                {{
                  category.budgetAmount === 0
                    ? 0
                    : (category.phaseExpenses / category.budgetAmount)
                      | percentage
                }}
              </td>
              <td
                class="is-info has-text-right"
                v-if="activityType == 2"
                :cy-data="'activity'"
              >
                {{ category.declaredActivity | number }}
              </td>
              <td
                class="is-info has-text-right"
                v-if="activityType == 2"
                :cy-data="'faepca'"
              >
                {{
                  category.budgetAmount === 0
                    ? 0 - category.invoicedOrders
                    : ((category.phaseExpenses * category.amountOrders) /
                        category.budgetAmount -
                        category.invoicedOrders)
                      | number
                }}
              </td>
            </tr>
          </template>
          <tr class="total">
            <td class="is-info" :cy-data="'categoryName'" colspan="2">Total</td>
            <td
              class="is-info has-text-right"
              :cy-data="'referenceBudgetIncome'"
            >
              {{ selectedIncomesAmounts.total | number }}
            </td>
            <td
              class="is-info has-text-right"
              :cy-data="'validatedBudgetIncome'"
            >
              {{ totalBudgetApsExe | number }}
            </td>
            <td class="is-info has-text-right" :cy-data="'budgetIncome'">
              {{ sheet.totalIncomes | number }}
            </td>
            <td class="is-info has-text-right" :cy-data="'amountTs'">
              {{ sheet.totalAmountTs | number }}
            </td>
            <td class="is-info has-text-right" :cy-data="'amountOrders'">
              {{ sheet.totalAmountOrders | number }}
            </td>
            <td class="is-info has-text-right" :cy-data="'invoicedOrders'">
              {{ sheet.totalInvoicedOrders | number }}
            </td>
            <td class="is-info has-text-right" :cy-data="'invoiceLeft'">
              {{
                (sheet.totalAmountOrders - sheet.totalInvoicedOrders) | number
              }}
            </td>
            <!-- Activity = 1, Real progress -->
            <td
              class="is-info has-text-centered"
              v-if="activityType == 1"
              :cy-data="'progress'"
            >
              {{ sheet.totalProgress | percentage }}
            </td>

            <td
              class="is-info has-text-right"
              v-if="activityType == 1"
              :cy-data="'activity'"
            >
              {{ sheet.totalDeclaredActivity | number }}
            </td>
            <td
              class="is-info has-text-right"
              v-if="activityType == 1"
              :cy-data="'faepca'"
            >
              {{
                (sheet.totalProgress * sheet.totalAmountOrders -
                  sheet.totalInvoicedOrders)
                  | number
              }}
            </td>

            <!-- Activity = 2, Financial progress -->
            <td
              class="is-info has-text-centered"
              v-if="activityType == 2"
              :cy-data="'progress'"
            >
              {{
                sheet.totalBudget === 0
                  ? 0
                  : (sheet.totalPhaseExpenses / sheet.totalBudget) | percentage
              }}
            </td>
            <td
              class="is-info has-text-right"
              v-if="activityType == 2"
              :cy-data="'activity'"
            >
              {{ sheet.totalDeclaredActivity | number }}
            </td>
            <td
              class="is-info has-text-right"
              v-if="activityType == 2"
              :cy-data="'faepca'"
            >
              {{
                sheet.totalBudget === 0
                  ? 0 - sheet.totalInvoicedOrders
                  : ((sheet.totalPhaseExpenses * sheet.totalAmountOrders) /
                      sheet.totalBudget -
                      sheet.totalInvoicedOrders)
                    | number
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <modal ref="addTsModal" @create="addTs" title="Ajouter un TS ou un devis">
      <!-- category -->
      <ValidationObserver ref="form">
        <many2one-field
          label="Catégorie"
          v-model="newTs.category"
          :fetch="$Api.Category.fetchParentCategories"
          reference="name"
          :entity="$EntitiesName.Category"
          :columns="{ name: 'Catégorie' }"
          :hasFilter="true"
          :inline="false"
          :edit="true"
          required
        ></many2one-field>
        <text-field
          label="Montant"
          type="number"
          v-model="newTs.amount"
          :inline="false"
          :edit="true"
          required
          rules="required"
        />
        <text-field
          label="Numéro de devis"
          v-model="newTs.orderNumber"
          :inline="false"
          :edit="true"
          required
          rules="required"
        />
      </ValidationObserver>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import CommentInline from "../../../components/elements/CommentInline.vue";

export default {
  name: "revenues-tab",
  props: ["id", "imputations"],
  components: {
    CommentInline,
  },
  data() {
    return {
      sheet: {},
      budgetControls: [],
      referenceBudgets: {},
      defaultReferenceBudget: {},
      referenceBudgetSelected: "Recettes Référence",
      orders: [],
      budget: {},
      count: 0,
      newTs: {
        ordered: false,
      },
      referenceBudgetSelectorActive: false,
      total: {
        incomes: 0,
        amountTs: 0,
        amountOrders: 0,
        amountInvoiced: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      isEditing: "App/isEdition",
      authToken: "Auth/getToken",
    }),
    categories() {
      let categories = {};

      // Parse Sheet Categories
      if (this.sheet.categories) {
        this.sheet.categories.forEach((category) => {
          if (!categories[category.categoryName]) {
            categories[category.categoryName] = {
              ...category,
              orders: [],
              amountTs: 0,
              referenceBudgetIncomeId: null,
              id: category.categoryId,
            };
            return;
          }
          categories[category.categoryName] = {
            ...categories[category.categoryName],
            ...category,
          };
        });
      }

      // Parse Orders
      this.orders.forEach((order) => {
        if (!categories[order.category.name])
          categories[order.category.name] = {
            orders: [],
          };

        categories[order.category.name].orders.push({
          orderNumber: order.orderNumber,
          amount: order.orderBreakdowns.reduce(
            (acc, val) => acc + (+val.amount || 0),
            0
          ),
          invoiced: order.orderBreakdowns.reduce(
            (acc, val) => acc + (+val.invoiced || 0),
            0
          ),
          ordered: order.ordered,
        });
      });

      return categories;
    },
    // totalAmountOrders() { return Object.values(this.categories).reduce((acc, val) => acc + val.amountOrders, 0) },
    // totalInvoicedOrders() { return Object.values(this.categories).reduce((acc, val) => acc + (val.invoicedOrders || 0), 0) },
    // totalAmountTs() { return Object.values(this.categories).reduce((acc, val) => acc + (val.amountTs || 0), 0) },
    activityType() {
      return !this.sheet.project ? 1 : this.sheet.project.activity;
    },
    selectedIncomesAmounts() {
      let incomes = {
        total: 0,
      };
      if (!this.sheet || !this.sheet.categories) {
        return incomes;
      }

      if (typeof this.referenceBudgetSelected === "string") {
        this.sheet.categories.forEach((cat) => {
          incomes[cat.categoryName] = cat.incomes;
          incomes.total += cat.incomes;
        });
        return incomes;
      }

      this.referenceBudgetSelected.referenceBudgetIncomes.forEach((inc) => {
        incomes[inc.categoryName] = inc.amount;
        incomes.total += inc.amount;
      });
      return incomes;
    },
    sheetState() {
      return this.sheet.state ? this.sheet.state.code : "draft";
    },
    validatedBudget() {
      if (!this.sheet.lastValidatedBudget) {
        return null;
      }
      const budgets = {
        0: null,
        1: "incomeAps",
        2: "incomeExe",
      };
      return budgets[this.sheet.lastValidatedBudget];
    },
    totalBudgetApsExe() {
      if (!this.sheet.categories || !this.validatedBudget) {
        return 0;
      }

      return this.sheet.categories.reduce(
        (acc, val) => acc + (val[this.validatedBudget] || 0),
        0
      );
    },
  },
  mounted() {
    this.initVal();
  },
  watch: {
    id() {
      this.initVal();
    },
  },
  methods: {
    initVal() {
      this.$Api.ReferenceBudget.fetchReferenceBudgetsForSheet.bind(
        this,
        this.id
      )((data) => {
        data.forEach((budget) => {
          if (!budget.milestone) {
            this.defaultReferenceBudget = budget;
            return;
          }
          this.referenceBudgets[budget.referenceDate + "-" + budget.id] =
            budget;

          if (
            typeof this.referenceBudgetSelected === "string" ||
            budget.referenceDate > this.referenceBudgetSelected.referenceDate
          ) {
            this.referenceBudgetSelected = budget;
          }
        });
      });
      this.$Api.Sheet.fetchSheetVm.bind(
        this,
        this.id
      )((data) => {
        this.sheet = data;
        this.newTs.project = data.project;
      });
      this.$Api.Order.fetchOrdersForSheet.bind(
        this,
        this.id
      )((data) => {
        this.orders = data;
      });
    },
    updateAmount(val, categoryId) {
      if (val === "") val = 0;
      const idx = this.defaultReferenceBudget.referenceBudgetIncomes.findIndex(
        (i) => i.category.id === categoryId
      );
      this.defaultReferenceBudget.referenceBudgetIncomes[idx].amount = val;
    },
    cancel() {
      this.$store.dispatch("App/setEditing", false);
      this.sheet.categories.forEach((cat) => {
        const idx =
          this.defaultReferenceBudget.referenceBudgetIncomes.findIndex(
            (i) => i.category.id === cat.categoryId
          );
        this.defaultReferenceBudget.referenceBudgetIncomes[idx].amount =
          cat.incomes;
      });
      this.count++;
    },
    save() {
      return axios
        .put(
          `/reference-budgets/${this.defaultReferenceBudget.id}`,
          this.defaultReferenceBudget
        )
        .then((response) => {
          if (response.data.success) {
            this.initVal();
            this.$store.dispatch("App/setEditing", false);
          }
        });
    },
    openOrderModal() {
      if (this.imputations.length === 0) {
        this.$awn.warning(
          "Vous ne pouvez pas ajouter de TS car la fiche affaire n'est associée à aucune imputation."
        );
        return;
      }
      this.$refs.addTsModal.open();
    },
    addTs() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        axios
          .post("/orders/imputation/" + this.imputations[0].id, this.newTs)
          .then(() => {
            this.newTs = {
              ordered: false,
              project: this.sheet.project,
            };
            this.$refs.addTsModal.close();
            this.initVal();
          });
      });
    },
    selectReferenceBudget(selection) {
      this.referenceBudgetSelected = selection;
      this.referenceBudgetSelectorActive = !this.referenceBudgetSelectorActive;
    },
  },
};
</script>

<style scoped lang="scss">
.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  min-height: 34px;
}
.button {
  display: block;
  white-space: initial;
  height: initial;
  border: 1px solid #fff;
  & > span {
    margin-right: 4px;
  }
  &:focus {
    box-shadow: initial;
  }
  &:hover {
    border-color: #eee;
  }
}
.no-border {
  border: none;
  div {
    width: 100%;
    span {
      width: 30%;
      display: inline-block;
    }
  }
}

.panel-block:first-child {
  margin-bottom: 50px;
}

.download-icon {
  margin-left: 6px;
}

.dropdown-item {
  cursor: pointer;
  padding: 6px 30px;
  text-align: center;
  &:hover {
    background-color: #eee;
  }
}

.sub-total {
  opacity: 0.9;
  td {
    border: none;
  }
}
.total {
  font-weight: bold;
}
</style>
