<template>
    <div class="budget">
      <div class="actions">
        <button class="button is-warning" v-if="!budgetEdit && !importMode && getRights('budget').update && !value.validated" @click="importMode = !importMode">Importer</button>
        <button class="button is-warning" v-if="!budgetEdit && !importMode && getRights('budget').update && !value.validated" @click="budgetEdit = true">Editer</button>
        <button class="button is-warning" v-if="budgetEdit || importMode" @click="cancel">Annuler</button>
        <button class="button is-warning" v-if="budgetEdit || importMode" @click="save">Sauvegarder</button>
        <button class="button is-success" v-if="options.includes($UserOptions.BUDGETS.VALIDATE) && !value.validated && !budgetEdit && !importMode && sheetState !== $States.VALIDATED" @click="$refs.validateBudgetModal.open()">Valider</button>
        <button
          class="button is-warning"
          v-if="invalidateBudget && options.includes($UserOptions.BUDGETS.VALIDATE) && value.validated && !budgetEdit && !importMode && sheetState !== $States.VALIDATED"
          @click="$refs.invalidateBudgetModal.open()"
        >Dé-valider</button>
      </div>

      <div class="title-total">
        <h3 class="title is-4 has-text-warning">Recettes</h3>
        <span class="line"></span>
        <h3 class="title is-5 has-text-warning" cy-data="totalIncomes">{{ totalIncomes | priceEUR }}</h3>
      </div>
      <table v-if="!importMode" class="table" style="width: 100%">
        <thead>
          <tr>
            <th>Catégorie</th>
            <th class="has-text-right">Montant</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(income, index) in budget.budgetIncomes" :key="'i_' + index">
            <td>{{ income.category.name }}</td>
            <td class="has-text-right">
              <text-field type="price" :value="income.amount" @input="updateIncome($event, index)" :edit="budgetEdit" />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="title-total">
        <h3 class="title is-4 has-text-warning">Dépenses</h3>
        <span class="line"></span>
        <h3 class="title is-5 has-text-warning" cy-data="totalExpenses">{{ totalAmount | priceEUR }}</h3>
      </div>
      <table v-if="!importMode" class="table" style="width: 100%">
        <thead class="sticky">
          <tr>
            <th>Catégorie</th>
            <th>Sous-catégorie</th>
            <th>Prestation</th>
            <template v-if="sheet && sheet.controlType.code === ControlTypeCodes.ACCOUNTING_NATURES">
              <th>Sous-prestation</th>
              <th class="has-text-right">Hypothèse</th>
              <th>Unité</th>
              <th class="has-text-right">Prix unitaire</th>
              <th class="has-text-right">Quantité base construction</th>
              <th class="has-text-right">
                <button
                  v-if="budgetEdit && options.includes($UserOptions.BUDGETS.SET_QUANTITIES) && sheet.controlType.code === this.ControlTypeCodes.ACCOUNTING_NATURES"
                  @click="setContructionBaseQuantities"
                  class="floating-btn button is-small is-info" cy-data="setQuantities"
                >
                  <font-awesome-icon icon="exchange-alt" />
                </button>
                Quantités retenues
              </th>
              <th class="has-text-right">Coeff. CDP</th>
            </template>
            <th class="has-text-right">Montant</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(prestation, index) in budget.budgetPrestations" :key="'p_' + index">
            <td>{{ prestation.categoryName }}</td>
            <td>{{ prestation.subCategoryName }}</td>
            <td>{{ prestation.prestationName }}</td>
            <template v-if="sheet && sheet.controlType.code === ControlTypeCodes.ACCOUNTING_NATURES">
              <td>{{ prestation.subPrestation.subPrestationName.name }}</td>
              <td class="has-text-right">{{ prestation.subPrestation.hypothesis | percentage }}</td>
              <td>{{ prestation.subPrestation.oscarColumn.name }}</td>
              <td class="has-text-right">{{ prestation.subPrestation.price | priceEUR }}</td>
              <td class="has-text-right" v-if="prestation.columnValue || prestation.columnValue === 0">{{ prestation.columnValue | number(2)}}</td>
              <td class="has-text-right" v-else>-</td>
              <td :style="budgetEdit ? {minWidth: '110px'} : null"><text-field type="number" :decimals="2" :value="prestation.quantity" @input="updateQuantity($event, index)" :edit="budgetEdit" /></td>
              <td :style="budgetEdit ? {minWidth: '90px'} : null"><percentage-field :value="prestation.ratioCdp" @input="updateRatio($event, index)" :edit="budgetEdit" /></td>
              <td class="has-text-right">{{ prestation.totalAmount | priceEUR }}</td>
            </template>
            <td v-else :style="budgetEdit ? {minWidth: '90px'} : null"><text-field type="price" v-model="prestation.totalAmount" :edit="budgetEdit" /></td>
          </tr>
        </tbody>
      </table>

      <ImportBudget v-if="importMode" v-model="importItems" :columns="handsonColumns" />

      <modal ref="validateBudgetModal" @create="validate" action="Valider" title="Validation d'un budget">
        <div class="message" :class="{ 'is-info': alertLevel === 'info', 'is-warning': alertLevel === 'warning' }">
          <div class="message-body">{{ validationMessage }}</div>
        </div>
      </modal>

      <modal ref="invalidateBudgetModal" @create="invalidate" action="Dé-valider" title="Dé-validation d'un budget">
        <div class="message is-warning">
          <div class="message-body">Etes-vous sûrs de vouloir dévalider ce budget ?</div>
        </div>
      </modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios'
import ImportBudget from '../../views/imports/Budget.vue'
import { Codes as ControlTypeCodes } from '../../constants/ControlType'

export default {
  name: 'budget',
  components: {
    ImportBudget
  },
  props: {
    value: {},
    sheet: Object,
    validateBudget: Function,
    invalidateBudget: Function,
    validationMessage: String,
    alertLevel: String
  },
  data() {
    return {
      ControlTypeCodes,
      budget: {},
      budgetEdit: false,
      importMode: false,
    }
  },
  computed: {
    ...mapGetters({
      options: 'Auth/getOptions',
      getRights: 'Auth/getRights'

    }),
    totalAmount() {
      if(!this.budget.budgetPrestations) return 0

      return this.budget.budgetPrestations.reduce((acc, val) => acc + (+val.totalAmount || 0), 0)
    },
    totalIncomes() {
      if(!this.budget.budgetIncomes) return 0

      return this.budget.budgetIncomes.reduce((acc, val) => acc + (+val.amount || 0), 0)
    },
    sheetState() {
      if(!this.sheet) return ''

      return this.sheet.state.code
    },
    handsonColumns() {
      let columns = [
        {
          label: 'Type',
          key: 'type',
          readOnly: true
        },
        {
          label: 'Catégorie',
          key: 'category',
          readOnly: true
        },
        {
          label: 'Sous-catégorie',
          key: 'subCategory',
          readOnly: true
        },
        {
          label: 'Prestation',
          key: 'prestation',
          readOnly: true
        },
      ]
      if(this.sheet.controlType.code === this.ControlTypeCodes.ACCOUNTING_NATURES) {
        const additional = [
          {
          label: 'Sous-prestation',
          key: 'subPrestation',
          readOnly: true
        },
        {
          label: 'Hypothèse',
          key: 'hypothesis',
          type: 'numeric',
          readOnly: true
        },
        {
          label: 'Unité',
          key: 'unit',
          readOnly: true
        },
        {
          label: 'Prix unitaire',
          key: 'price',
          type: 'numeric',
          readOnly: true
        },
        {
          label: 'Quantités base construction',
          key: 'cbQuantity',
          type: 'numeric',
          readOnly: true
        },
        {
          label: 'Quantités retenues',
          key: 'quantity',
          type: 'numeric'
        },
        {
          label: 'Coeff. CDP',
          key: 'ratioCdp',
          type: 'numeric'
        },
        ]
        columns.push(...additional)
      }

      columns.push({
        label: 'Montant',
        key: 'amount',
        type: 'numeric',
      })

      return columns
    },
    importItems: {
      get() {
        let items = []
        if(this.budget.budgetIncomes) {
          this.budget.budgetIncomes.forEach(bi => {
            items.push({
              type: 'recette',
              category: bi.category.name,
              subCategory: null,
              prestation: null,
              subPrestation: null,
              hypothesis: null,
              unit: null,
              price: null,
              cbQuantity: null,
              quantity: null,
              ratioCdp: null,
              amount: bi.amount,
            })
          });
        }

        if(this.budget.budgetPrestations) {
          this.budget.budgetPrestations.forEach(prestation => {
            items.push({
              type: 'dépense',
              category: prestation.categoryName,
              subCategory: prestation.subCategoryName,
              prestation: prestation.prestationName,
              subPrestation: prestation.subPrestation ? prestation.subPrestation.subPrestationName.name : '',
              hypothesis: prestation.subPrestation ? prestation.subPrestation.hypothesis : 0,
              unit: prestation.subPrestation ? prestation.subPrestation.oscarColumn.name : '',
              price: prestation.subPrestation ? prestation.subPrestation.price : 0,
              cbQuantity: prestation.subPrestation ? prestation.subPrestation.oscarColumn.name : '',
              quantity: prestation.quantity,
              ratioCdp: prestation.ratioCdp,
              amount: prestation.totalAmount,
              amountEditable: this.sheet.controlType.code === this.ControlTypeCodes.IMPUTATIONS
            })
          });
        }
        return items
      },
      set(val) {
        let nbIncomes = 0
        for(let i = 0; i < val.length; i++) {
          if(val[i].type === 'recette') {
            nbIncomes++
            this.updateIncome(val[i].amount, i)
            continue
          }
          if(this.sheet.controlType.code === this.ControlTypeCodes.ACCOUNTING_NATURES) {
            this.updateQuantity(val[i].quantity, i-nbIncomes)
            this.updateRatio(val[i].ratioCdp, i-nbIncomes)
          } else {
            this.budget.budgetPrestations[i-nbIncomes].totalAmount = val[i].amount
          }
        }
      }
    }
  },
  mounted() {
    this.initValues()
  },
  watch: {
    value() {
      this.initValues()
    }
  },
  methods: {
    initValues() {
      if(!this.value) {
        return
      }
      this.budget = JSON.parse(JSON.stringify(this.value))
    },
    cancel() {
      this.budgetEdit = false
      this.importMode = false
      this.initValues()
    },
    save() {
      const budget = {
        ...this.budget,
        sheet: this.sheet
      }
      return axios.put(`/budgets/${this.budget.id}`, budget).then(() => {
        this.$emit('input', this.budget)
        this.budgetEdit = false
        this.importMode = false
      });
    },
    validate() {
      this.validateBudget()
      this.$refs.validateBudgetModal.close()
    },
    invalidate() {
      this.invalidateBudget()
      this.$refs.invalidateBudgetModal.close()
    },
    updateQuantity(val, index) {
      if(val === '') val = 0
      const prestation = this.budget.budgetPrestations[index]

      prestation.quantity = val
      prestation.totalAmount = (Number(prestation.subPrestation.hypothesis) * Number(val) * prestation.subPrestation.price * Number(prestation.ratioCdp)).toFixed(2)
    },
    updateRatio(val, index) {
      if(val === '') val = 0
      const prestation = this.budget.budgetPrestations[index]

      prestation.ratioCdp = val
      prestation.totalAmount = (Number(prestation.subPrestation.hypothesis) * Number(prestation.quantity) * prestation.subPrestation.price * Number(val)).toFixed(2)
    },
    updateIncome(val, index) {
      if(val === '') val = 0
      this.budget.budgetIncomes[index].amount = val
    },
    setContructionBaseQuantities() {
      for(let i = 0; i < this.budget.budgetPrestations.length; i++) {
        if(!!this.budget.budgetPrestations[i].columnValue || this.budget.budgetPrestations[i].columnValue === 0) {
          this.updateQuantity(this.budget.budgetPrestations[i].columnValue, i)
        }
      }
    }
  }

}
</script>

<style scoped lang="scss">
th {
  position: relative;
}
.sticky {
  position: sticky;
  top: 52px;
  background: white;
}
.button {
  margin-left: 4px;
}
.reference-budget {
  width: 100%;
}
.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.title-total{
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
  >* {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
  }
  .line {
    flex: 1;
    border-bottom: 1px dotted $axione-orange;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.floating-btn {
  position: absolute;
  left: -10px;
  top: 5px;
}
</style>
