<template>
  <div class="information-tab panel-block">
    <div class="column is-half">
      <text-field
        label="Nom"
        v-model="sheet.name"
        :edit="editMode"
        required
        data-test="sel-sheet-name"
      ></text-field>
      <field label="Projet" readonly>
        <span
          ><router-link :to="'/project/' + project.id">{{
            project.name
          }}</router-link></span
        >
      </field>
      <field label="Modèle de fiche" readonly>
        <span
          ><router-link
            v-if="sheet.sheetTemplate"
            :to="'/sheet-template/' + sheet.sheetTemplate.id"
            >{{ sheet.sheetTemplate.name }}</router-link
          ></span
        >
      </field>
      <many2one-field
        label="Responsable"
        v-model="sheet.owner"
        :fetch="$Api.User.fetchUsers"
        reference="name"
        entity="user"
        :columns="{ name: 'Nom' }"
        :hasFilter="true"
        :edit="editMode"
        required
      ></many2one-field>
      <field label="Type de contrôle budgétaire" readonly
        ><span>{{ sheet.controlType && sheet.controlType.name }}</span></field
      >
      <many2one-field
        label="Statut de l'affaire"
        v-model="sheet.sheetStatus"
        :fetch="$Api.SheetStatus.fetchSheetStatus"
        reference="name"
        entity="sheetStatus"
        :columns="sheetStatusColumns"
        :hasFilter="true"
        :edit="editMode"
        required
      ></many2one-field>
      <many2one-field
        label="Statut de gestion"
        v-model="sheet.administrationStatus"
        :fetch="$Api.AdministrationStatus.fetchAdministrationStatuses"
        reference="name"
        entity="administrationStatus"
        :columns="{ name: 'Statuts de gestion' }"
        :hasFilter="true"
        :edit="editMode"
        required
      ></many2one-field>
      <text-field
        label="Jalon"
        v-model="sheet.milestone"
        :edit="editMode"
      ></text-field>
      <date-field
        label="Date de début"
        v-model="sheet.startDate"
        :edit="editMode"
        data-test="sel-sheet-start-date"
      ></date-field>
      <date-field
        label="Date de fin"
        v-model="sheet.endDate"
        :edit="editMode"
        @focusout="updateSheetEndDate"
        data-test="sel-sheet-end-date"
      ></date-field>

      <div class="panel">
        <panel-header
          title="Imputations"
          @add="addImputation"
          :fetch="
            $Api.Imputation.fetchAvailableImputations.bind(this, project.id)
          "
          :columns="imputationColumns"
          :hasFilter="true"
        >
          <button
            slot="activator"
            cy-data="add-imputation-button"
            class="button is-small is-success"
          >
            <font-awesome-icon icon="plus" />
          </button>
        </panel-header>
        <div class="panel-block">
          <table class="table is-fullwidth">
            <tbody>
              <tr v-for="(i, index) in sheet.imputations" :key="index">
                <td class="has-text-left">{{ i.eotp }}</td>
                <td>
                  <div class="is-pulled-right">
                    <button
                      class="button is-small is-danger"
                      @click="removeImputation(i)"
                    >
                      <font-awesome-icon icon="trash-alt" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="panel">
        <panel-header
          title="Sous-projets Oscar"
          @add="addSubProject"
          :fetch="
            $Api.OscarSubProject.fetchOscarSubProjectsAvailable.bind(
              this,
              project.id
            )
          "
          :columns="{
            oscarSubProjectName: 'Sous-projet OSCAR',
            arcgisSubProjectName: 'Nom Arcgis',
          }"
          :hasFilter="true"
        >
          <button
            slot="activator"
            data-test="sel-user-add-imputation"
            class="button is-small is-success"
          >
            <font-awesome-icon icon="plus" />
          </button>
        </panel-header>
        <div class="panel-block">
          <table class="table is-fullwidth">
            <tbody>
              <tr v-for="(o, index) in sheet.oscarSubProjects" :key="index">
                <td class="has-text-left">{{ o.oscarSubProjectName }}</td>
                <td class="has-text-left">{{ o.arcgisSubProjectName }}</td>
                <td>
                  <div class="is-pulled-right">
                    <button
                      class="button is-small is-danger"
                      @click="removeSubProject(o)"
                    >
                      <font-awesome-icon icon="trash-alt" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="column is-half"></div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "information-tab",
  props: {
    value: {},
  },
  data() {
    return {
      sheet: {},
      currentTab: 0,
      sheetTemplateColumns: {
        name: "Modèle de fiche affaire",
        "project.name": "Projet",
      },
      sheetStatusColumns: {
        name: "Jalon",
      },
      imputationColumns: {
        eotp: "Imputation",
      },
    };
  },
  computed: {
    ...mapGetters({
      editMode: "App/isEdition",
    }),
    sheetTemplate() {
      return this.sheet.sheetTemplate ? this.sheet.sheetTemplate : {};
    },
    project() {
      return this.sheet.project ? this.sheet.project : {};
    },
  },
  mounted() {
    this.initData();
  },
  watch: {
    value() {
      this.initData();
    },
    "sheet.startDate"() {
      this.updateSheetEndDate(this.sheet.endDate);
    },
  },
  methods: {
    initData() {
      this.sheet = this.value;
    },

    /* Imputations */
    addImputation(imputation) {
      imputation.sheet = this.sheet;
      axios
        .post(`/sheets/${this.sheet.id}/imputation/${imputation.id}`)
        .then(() => {
          this.$emit("refresh");
        });
    },
    removeImputation(imputation) {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          imputation.sheet = null;
          axios
            .delete(
              `/sheets/${this.sheet.id}/imputation/${imputation.id}`,
              imputation
            )
            .then(() => {
              this.$emit("refresh");
            });
        }
      );
    },
    /* SubProjects */
    addSubProject(osp) {
      axios
        .post(`/sheets/${this.sheet.id}/oscar-subproject/${osp.id}`)
        .then(() => {
          this.$emit("refresh");
        });
    },
    removeSubProject(osp) {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          axios
            .delete(`/sheets/${this.sheet.id}/oscar-subproject/${osp.id}`)
            .then(() => {
              this.$emit("refresh");
            });
        }
      );
    },
    updateSheetEndDate(val) {
      if (moment(val).isBefore(this.sheet.startDate))
        this.$nextTick(() => (this.sheet.endDate = this.sheet.startDate));
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  th,
  td {
    text-align: center;
  }
}

.no-border {
  border: none;
  div {
    width: 100%;
    span {
      width: 30%;
      display: inline-block;
    }
  }
}
</style>
