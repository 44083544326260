<template>
    <div class="panel infos">
      <div class="panel-block is-block no-border">
        <hot-table ref="spreadsheet" :settings="settings" style="min-height: 50vh; overflow: auto;"></hot-table>
      </div>
    </div>
</template>
<script>
import { HotTable } from '@handsontable/vue';

export default {
  name: 'budget-import',
  components: {
    HotTable
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    columns: {},
  },
  data: function() {
    return {
      settings: {
        data: [], // Initial data during init.
        colHeaders: [],
        rowHeaders: true,
        stretchH: 'all',
        columns: [],
        afterChange: (changes) => {
          const hypothesisIdx = this.columns.findIndex(o => o.key === 'hypothesis')
          const priceIdx = this.columns.findIndex(o => o.key === 'price')
          const quantityIdx = this.columns.findIndex(o => o.key === 'quantity')
          const ratioCdpIdx = this.columns.findIndex(o => o.key === 'ratioCdp')
          const amountIdx = this.columns.findIndex(o => o.key === 'amount')
          const datasource = this.$refs.spreadsheet.hotInstance?.getSourceData()
          if(changes) {
            changes.forEach(change => {
              if(change[1] === quantityIdx) {
                this.$refs.spreadsheet.hotInstance.setDataAtCell(change[0], amountIdx, (datasource[change[0]][hypothesisIdx] * datasource[change[0]][priceIdx] * change[3] * datasource[change[0]][ratioCdpIdx]).toFixed(2))
              }

              if(change[1] === ratioCdpIdx)
                this.$refs.spreadsheet.hotInstance.setDataAtCell(change[0], amountIdx, (datasource[change[0]][hypothesisIdx] * datasource[change[0]][priceIdx] * datasource[change[0]][quantityIdx] * change[3]).toFixed(2))
            })
          }

          if (datasource) {
            // Trim all the data in the spreadsheet
            const data = this.value;
            datasource.forEach((d, index) => {
              d.forEach((c, colIdx) => {
                c = (c && typeof(c) === 'string') ? c.trim() : c
                const key = this.columns[colIdx].key;
                data[index][key] = c
              })
            });
            this.$emit('input', data);
          }
          this.validate();
        },
        afterRender: () => {
          this.validate();
        },
        cells: (row, col) => {
          var cellProperties = {};
          cellProperties.className = `column-${this.columns[col].key}`;

          const data = this.value;
          if(!data || !data[row]) {
            return cellProperties;
          }

          cellProperties.readOnly =
            this.settings.columns[col].readOnly ||
            (data[row].type === 'recette' && this.columnsDefinition[col].key === 'quantity') ||
            (data[row].type === 'recette' && this.columnsDefinition[col].key === 'ratioCdp') ||
            (data[row].type === 'dépense' && !data[row].amountEditable && this.columnsDefinition[col].key === 'amount' )

          return cellProperties;
        },
      },
      columnsDefinition: [],
    };
  },
  watch: {
    value() {
      this.initValue()
    }
  },
  mounted() {
    this.initValue()
  },
  methods: {
    initValue() {
      this.columnsDefinition = []
      this.settings.data = []
      this.columns.forEach((c) => this.columnsDefinition.push(c));

      this.settings.colHeaders = this.columnsDefinition.map((c) => c.label);
      this.settings.columns = this.columnsDefinition.map((c) => {
            return {
              label: c.label,
              type: c.type,
              source: c.source,
              strict: c.strict || false,
              allowInvalid:  c.allowInvalid === false ? c.allowInvalid : true,
              readOnly: c.readOnly || false
            };
          });

      Object.values(this.value).forEach(row => {
        const cells = []

        this.columnsDefinition.forEach(column => {
          cells.push(row[column.key])
        })

        this.settings.data.push(cells)
      })
    },
    validate() {
      if(!this.$refs.spreadsheet.hotInstance) {
        return;
      }
      const spreadsheet = this.$refs.spreadsheet.hotInstance;

      const lineCount = this.settings.data.length;
      this.validLines = 0;
      for(let row = 0; row < lineCount; row++) {
        const rowData = this.settings.data[row];

        // Skip empty lines
        if(!rowData.slice(1).some((e) => e != '')) {
          continue;
        }

        let validLine = true;
        // Validate each columns.
        for(let col = 4; col < rowData.length; col++) {
          const cellData = rowData[col];

          let isValid = false;

          if(this.columnsDefinition[col].required && cellData == '') {
            isValid = false;
          }
          else
          if(this.columnsDefinition[col].validator) {
            isValid = this.columnsDefinition[col].validator(cellData)
          }
          else {
            isValid = true;
          }


          if(validLine) {
            validLine = isValid;
          }

          if(spreadsheet.getCellMeta(row, col).readOnly) {
            continue;
          }

          // Change cell rendering if possible.
          const cell = spreadsheet.getCell(row, col);
          if(!cell) {
            continue;
          }

          cell.style.background = isValid ? '#CEC' : '#F00';
        }
        if(validLine) {
          this.validLines++;
        }
      }
    },
  }
}
</script>
<style>

</style>
