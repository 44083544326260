<template>
  <div class="evolutions-tab panel-block column">
    <div class="panel-block evolutions">
      <table class="table">
        <thead>
          <tr>
            <th class="nowrap invisible is-info">Nom budget</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="is-info">Recettes</td>
          </tr>
          <tr>
            <td class="is-info">Débours</td>
          </tr>
          <tr>
            <td class="is-info">Marge</td>
          </tr>
          <tr>
            <td class="is-info">% Marge</td>
          </tr>
        </tbody>
      </table>
      <table class="table is-fullwidth budget" v-for="(budget, index) in evolutions" :key="index + '_' + key">
        <thead>
          <tr>
            <th class="has-text-centered nowrap" :cy-data="'budget-' + index" :title="displayDate(budget.referenceDate)">{{ budget.name ? budget.name : budget.phase.name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="has-text-centered">{{ budget.totalIncomes | priceEUR }}</td>
          </tr>
          <tr>
            <td class="has-text-centered">{{ budget.totalAmount | priceEUR }}</td>
          </tr>
          <tr>
            <td class="has-text-centered">{{ budget.totalIncomes - budget.totalAmount | priceEUR }}</td>
          </tr>
          <tr>
            <td class="has-text-centered">{{ budget.totalIncomes ? 1 - (budget.totalAmount / budget.totalIncomes) : 0 | percentage(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="panel-block">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Historisations</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
           <tr v-for="(bc, index) in budgetControls" :key="index">
            <td>{{ bc.referenceDate | monthYear }}</td>
            <td>
              <div class="is-pulled-right">
                <form
                  class="inline-form"
                  method="POST"
                  target="_blank"
                  :action="axios.defaults.baseURL + '/reference-budgets/' + bc.id + '/pdf'"
                >
                  <button
                    type="submit"
                    class="button is-small is-success"
                  >
                    Télécharger
                    <font-awesome-icon class="download-icon" icon="download" />
                  </button>
                  <input type="hidden" name="token" :value="authToken">
                </form>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'evolutions-tab',
  props: {
    id: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      budgetControls: [],
      budgets: [],
      referenceBudgets: [],
      evolutions: [],
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      authToken: 'Auth/getToken'
    }),
  },
  mounted() {
    if(!this.id) return

    this.initVal()
  },
  watch: {
    id() {
      this.initVal()
    }
  },
  methods: {
    initVal() {
      let evolutions = []

      Promise.all([
        this.$Api.Sheet.fetchBudgetControls(this.id, data => {
          this.budgetControls = data.sort((a, b) => {
            if(a.referenceDate > b.referenceDate) {
              return -1
            }
            return 1
          })
          return data
        }),
        this.$Api.Sheet.fetchBudgets(this.id, data => data.filter(r => r.validated)),
        this.$Api.ReferenceBudget.fetchReferenceBudgetsForSheet(this.id, data => {
          const idx = data.findIndex((b) => b.milestone === null)
          if(idx > -1) {
            data.splice(idx, 1)
          }
          return data
        })
      ]).then(result => {
        evolutions = [...result[0], ...result[1], ...result[2]]

        this.evolutions = evolutions.sort((a, b) => {
          if(a.referenceDate < b.referenceDate) {
            return -1
          }
          return 1
        })
      })
    },
    displayDate(date) {
      const d = new Date(date)
      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
.invisible {
  opacity: 0;
}
.evolutions {
  overflow-x: auto;
  th {
    padding: 8px 36px;
  }
}

.table:last-child {
  margin-bottom: 1.5rem;
}

.no-border {
  border: none;
  div {
    width: 100%;
    span {
      width: 30%;
      display: inline-block;
    }
  }
}

.panel-block:first-child {
  margin-bottom: 50px;
}

.download-icon {
  margin-left: 6px;
}
</style>
