<template>
  <div
    class="discussion"
    :class="
      expired ? 'has-background-danger-light' : 'has-background-primary-light'
    "
  >
    <div class="header">
      <div>
        En charge : <strong>{{ comment.responsible.name }}</strong> - Echéance :
        <strong :class="{ 'has-text-danger': expired }">{{
          comment.dueDate | date
        }}</strong>
      </div>
      <div class="actions">
        <button
          class="button is-small is-success"
          @click="openCloseCommentModal('Fermé !')"
        >
          Cloturer
        </button>
      </div>
    </div>
    <p class="reply-info">
      {{ comment.createdAt | date("DD/MM/YYYY HH:mm") }} -
      <strong>{{ comment.creator.name }}</strong>
    </p>
    <Comment
      :comment="comment"
      @deleted="$emit('deleted')"
      @close="$emit('close')"
    />

    <modal
      ref="closeCommentModal"
      @create="close"
      title="Message de clôture"
      :action="'Valider'"
    >
      <div class="columns">
        <div class="column is-half">
          <ValidationObserver ref="form">
            <TextareaField edit v-model="closeMessage" />
          </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Comment from "../elements/Comment.vue";
import TextareaField from "../fields/TextareaField.vue";

export default {
  components: { Comment, TextareaField },
  name: "discussion",
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      closeMessage: null,
    };
  },
  computed: {
    expired() {
      const today = new Date();
      const year = today.getFullYear();
      const month =
        today.getMonth() < 9
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1;
      const day =
        today.getDate() < 10 ? "0" + today.getDate() : today.getDate();

      return (
        Date.parse(year + "-" + month + "-" + day) >
        Date.parse(this.comment.dueDate)
      );
    },
  },
  methods: {
    openCloseCommentModal(message) {
      this.closeMessage = message;
      this.$refs.closeCommentModal.open();
    },
    close() {
      const comment = {
        ...this.comment,
        active: false,
        message: this.closeMessage,
      };

      return axios
        .put(`/comments/${this.comment.id}/close`, comment)
        .then((response) => {
          if (response.data.success) {
            this.$refs.closeCommentModal.close();
            this.$emit("close");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.discussion {
  position: relative;
  margin: 1em 0;
  position: relative;
  border-radius: 0.25em;
  padding: 1em;

  &:last-child {
    margin-bottom: 0;
  }
  & > * {
    margin-bottom: 10px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}
.reply-info {
  font-size: small;
  margin: 0 !important;
}
</style>
