<template>
  <div class="budget-tab" :key="'budget' + id">
    <div class="actions" :key="'auto' + sheetState">
      <div class="valid-date title is-5">
        Mois en cours :

        <div class="dropdown" :class="{ 'is-active': dateSelectorOpen }">
          <div class="dropdown-trigger">
            <div
              class="buton selector"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              @click="dateSelectorOpen = !dateSelectorOpen && !isEditing"
            >
              <span class="has-text-info">{{ selectedDate | monthYear }}</span>
            </div>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-item" @click="selectDate(validDate)">
                {{ validDate | monthYear }}
              </div>
              <div class="dropdown-item" @click="selectDate(currentDate)">
                {{ currentDate | monthYear }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        class="button is-info"
        @click="displayMainComment = !displayMainComment"
        v-if="!isEditing && sheetState !== $States.VALIDATED"
      >
        <font-awesome-icon icon="comment" />
      </button>
      <button
        v-if="
          !isEditing &&
          (((options.includes($UserOptions.BUDGET_CONTROLS.REVIEW) ||
            options.includes($UserOptions.BUDGET_CONTROLS.VALIDATE)) &&
            sheetState === $States.REVIEWED) ||
            (options.includes($UserOptions.BUDGET_CONTROLS.VALIDATE) &&
              sheetState === $States.VALIDATED)) &&
          sheetState !== $States.DRAFT
        "
        class="button is-warning"
        @click="changeState($States.DRAFT)"
      >
        Retour en brouillon
      </button>
      <button
        v-if="
          !isEditing &&
          options.includes($UserOptions.BUDGET_CONTROLS.REVIEW) &&
          sheetState !== $States.REVIEWED &&
          sheetState !== $States.VALIDATED
        "
        class="button is-success"
        @click="changeState($States.REVIEWED)"
      >
        Envoi en validation CFI
      </button>
      <button
        v-if="
          !isEditing &&
          options.includes($UserOptions.BUDGET_CONTROLS.VALIDATE) &&
          sheetState !== $States.VALIDATED
        "
        class="button is-success"
        @click="changeState($States.VALIDATED)"
      >
        Valider
      </button>
      <button
        v-if="
          !isEditing &&
          options.includes($UserOptions.BUDGET_CONTROLS.HISTORIZE) &&
          sheetState === $States.VALIDATED
        "
        cy-data="cy-historize-button"
        class="button is-success"
        @click="openHistorizationModal()"
      >
        Historisation
      </button>
      <button
        class="button is-warning"
        v-if="
          !isEditing &&
          getRights('budget').update &&
          sheetState !== $States.VALIDATED
        "
        @click="computeRad"
      >
        Calcul RAD Auto
      </button>
      <button
        class="button is-warning"
        v-if="
          !isEditing &&
          options.includes($UserOptions.BUDGET_CONTROLS.HISTORIZE) &&
          sheetState !== $States.VALIDATED
        "
        @click="resetRad"
      >
        RAZ RAD
      </button>
    </div>
    <div v-if="loading" class="loader-div">
      <i class="loader inline"></i>
    </div>
    <div
      v-if="!loading"
      class="textarea-container"
      :class="{ opened: displayMainComment || isEditing }"
    >
      <!-- Display of comments on the current reference budget -->
      <div v-if="isCurrentMonth">
        <div>
          <span class="title is-6">Commentaire de la fiche en brouillon:</span>
          <TextareaField :edit="isEditing" v-model="comments.draft.comment" />
        </div>

        <div v-if="comments.current.comment !== null">
          <span class="title is-6"
            >Commentaire de la fiche du mois courant historisé:</span
          >
          <TextareaField v-model="comments.current.comment" :readonly="true" />
        </div>
        <div v-if="comments.previousM1">
          <span class="title is-6">Commentaire du mois précédent:</span>
          <TextareaField
            v-model="comments.previousM1.comment"
            :readonly="true"
          />
        </div>
      </div>
      <!-- Display of comments on the preceding reference budget -->
      <div v-else>
        <div>
          <span class="title is-6">Commentaire de la fiche en brouillon:</span>
          <TextareaField :edit="isEditing" v-model="comments.draft.comment" />
        </div>

        <div v-if="comments.previousM1">
          <span class="title is-6">Commentaire du mois:</span>
          <TextareaField
            v-model="comments.previousM1.comment"
            :readonly="true"
          />
        </div>
        <div v-if="comments.previousM2">
          <span class="title is-6">Commentaire du mois précédent:</span>
          <TextareaField
            v-model="comments.previousM2.comment"
            :readonly="true"
          />
        </div>
      </div>
    </div>

    <PhysicalTemplate
      v-if="!loading && templateType === 1"
      :sheet="sheet"
      v-model="categories"
      :referenceBudgets="referenceBudgets"
      @refresh="initVal"
      :monthSelected="monthSelected"
    />
    <FinancialTemplate
      v-if="!loading && templateType === 2"
      :sheet="sheet"
      v-model="categories"
      :referenceBudgets="referenceBudgets"
      @refresh="initVal"
      :monthSelected="monthSelected"
    />

    <modal
      ref="historizationModal"
      @create="historizeBudgetControl"
      action="Historiser"
      title="Séléction de la période"
    >
      <div class="columns">
        <div class="column is-half">
          <selector-field
            :options="$Api.Sheet.getMonths"
            label="Mois"
            v-model="month"
            :inline="false"
            :edit="true"
          ></selector-field>
          <selector-field
            :options="$Api.Sheet.getYears"
            label="Année"
            v-model="year"
            :inline="false"
            :edit="true"
          ></selector-field>
        </div>
        <div class="column is-half" v-if="alreadyHistorized">
          <div class="message is-warning">
            <div class="message-body">
              Une historisation existe déjà pour cette période. En continuant
              elle sera écrasée par cette nouvelle historisation.
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import FinancialTemplate from "../templates/financialTemplate.vue";
import PhysicalTemplate from "../templates/physicalTemplate.vue";
import { Codes as ControlTypeCodes } from "../../../constants/ControlType";
import TextareaField from "../../../components/fields/TextareaField.vue";

export default {
  name: "budget-control-tab",
  components: {
    FinancialTemplate,
    PhysicalTemplate,
    TextareaField,
  },
  props: ["id", "sheetState"],
  data() {
    return {
      ControlTypeCodes,
      loading: false,
      sheet: {},
      categories: [],
      defaultReferenceBudget: {},
      referenceBudgets: {},
      month: null,
      year: null,
      historizedPeriods: [],
      displayMainComment: false,
      validDate: null,
      currentDate: new Date(),
      selectedDate: null,
      dateSelectorOpen: false,
      comments: {
        draft: { comment: "" },
        current: null,
        previousM1: null,
        previousM2: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      isEditing: "App/isEdition",
      getRights: "Auth/getRights",
      options: "Auth/getOptions",
    }),
    isCurrentMonth() {
      return (
        this.selectedDate &&
        this.selectedDate.getMonth() === new Date().getMonth()
      );
    },
    monthSelected() {
      if (!this.selectedDate) return;
      const today = new Date();
      return this.selectedDate.getMonth() === today.getMonth()
        ? "current"
        : "complete";
    },
    alreadyHistorized() {
      if (this.historizedPeriods.length === 0) return false;

      return (
        this.historizedPeriods.findIndex((period) => {
          const date = new Date(period);
          return (
            this.month == date.getMonth() + 1 && this.year == date.getFullYear()
          );
        }) !== -1
      );
    },
    templateType() {
      if (!this.sheet || !this.sheet.project) {
        return 1;
      }

      return this.sheet.project.activity;
    },
  },
  mounted() {
    let date = new Date();
    date.setMonth(date.getMonth() - 1);

    this.validDate = date;
    this.selectedDate = date;

    this.initVal();
  },
  watch: {
    id() {
      this.initVal();
    },
    "comments.draft.comment": function (val) {
      this.defaultReferenceBudget.comment = val;
    },
  },
  methods: {
    initVal() {
      // Skipping if the id is not set.
      if (!this.id) {
        return;
      }

      this.loading = true;
      this.$Api.Sheet.fetchSheetVm.bind(
        this,
        this.id
      )((data) => {
        this.sheet = data;
        this.categories = JSON.parse(JSON.stringify(data.categories));
        this.loading = false;
      });

      this.referenceBudgets = {};
      this.$Api.ReferenceBudget.fetchReferenceBudgetsForSheet.bind(
        this,
        this.id
      )((data) => {
        data.forEach((budget) => {
          if (!budget.milestone) {
            this.defaultReferenceBudget = budget;
            return;
          }
          this.referenceBudgets[budget.referenceDate + "-" + budget.id] =
            budget;
        });
      });
      this.loadComment();
    },

    loadComment() {
      this.$Api.Sheet.fetchCommentsForSheet(this.id).then((data) => {
        this.comments.previousM1 = data.previousM1;
        this.comments.previousM1.comment =
          this.comments.previousM1.comment ?? "Pas de commentaire";
        this.comments.previousM2 = data.previousM2;
        this.comments.previousM2.comment =
          this.comments.previousM2.comment ?? "Pas de commentaire";
        this.comments.current = data.current;
        this.comments.draft = data.draft;
      });
    },

    cancel() {
      this.$store.dispatch("App/setEditing", false);
      this.categories = JSON.parse(JSON.stringify(this.sheet.categories));
    },
    save() {
      let referenceBudgetPrestations = [];
      this.categories.forEach((cat) => {
        cat.prestations.forEach((p) => {
          referenceBudgetPrestations.push({
            referenceBudget: { id: this.defaultReferenceBudget.id },
            prestation:
              this.sheet.controlType.code ===
              this.ControlTypeCodes.ACCOUNTING_NATURES
                ? { id: p.prestationId }
                : null,
            imputation:
              this.sheet.controlType.code === this.ControlTypeCodes.IMPUTATIONS
                ? { id: p.prestationId }
                : null,
            id: p.id,
            rad: p.rad,
            capFnp: p.capFnp,
            amount: p.amount,
            previousAmount: p.previousAmount,
            amountAps: p.amountAps,
            amountExe: p.amountExe,
            progress: p.progress,
            previousProgress: p.previousProgress,
          });
        });
      });
      let budget = {
        ...this.defaultReferenceBudget,
        referenceBudgetPrestations: referenceBudgetPrestations,
      };
      return axios
        .put(`/reference-budgets/${budget.id}`, budget)
        .then((response) => {
          if (response.data.success) {
            this.initVal();
            this.$store.dispatch("App/setEditing", false);
          }
        });
    },
    openHistorizationModal() {
      this.$Api.ReferenceBudget.fetchBudgetControlsForSheet(this.id, (data) => {
        this.historizedPeriods = data.map(
          (budgetControl) => budgetControl.referenceDate
        );
      });
      this.month = new Date().getMonth();
      this.$refs.historizationModal.open();
    },
    changeState(action) {
      return axios
        .put(`/sheets/${this.id}/state/${action}`)
        .then((response) => {
          if (response.data.success) {
            this.$emit("state");
          }
        });
    },
    historizeBudgetControl() {
      const name =
        this.month.toString().length === 1
          ? this.year + "-0" + this.month + "-01"
          : this.year + "-" + this.month + "-01";
      return axios
        .post(
          `/reference-budgets/${this.defaultReferenceBudget.id}/historize`,
          { name }
        )
        .then((response) => {
          if (response.data.success) {
            this.$emit("historized");
            this.initVal();
            this.$refs.historizationModal.close();
          }
        });
    },
    computeRad() {
      this.categories.forEach((cat) => {
        cat.prestations.forEach((p) => {
          p.amount = p.previousAmount;
          p.rad = p.previousAmount - p.phaseExpenses - p.capFnp;
        });
      });
      this.save();
    },
    resetRad() {
      this.$Api.ReferenceBudget.resetRad(this.defaultReferenceBudget.id).then(
        () => {
          this.initVal();
        }
      );
    },
    selectDate(date) {
      this.selectedDate = date;
      this.dateSelectorOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
.budget-tab {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.5em 0.75em;
}

.button {
  margin-left: 4px;
}

.textarea-container {
  transition-duration: 0.7s;
  transition-property: max-height;
  max-height: 0;
  margin-top: 20px;
  & div {
    display: none;
  }
}
.textarea-container.opened {
  max-height: 450px;

  margin-top: 0px;

  margin-bottom: 20px;
  & div {
    display: block;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  min-height: 34px;
}

.loader-div {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.inline {
  display: inline-block;
  border-left-color: #0195b7;
  border-bottom-color: #0195b7;
  width: 25px;
  height: 25px;
}

.valid-date {
  margin-right: auto;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  & > * {
    margin-left: 4px;
  }
}
.selector {
  padding: calc(0.3em - 1px) 0.6em;
  border: 1px solid #dbdbdb;
  transition-duration: 100ms;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    border-color: var(--axione-dark-grey);
  }
  &:active {
    border-color: var(--axione-blue);
  }
}
.dropdown-item {
  cursor: pointer;
  padding: 6px 30px;
  &:hover {
    background-color: #eee;
  }
}
.dropdown-menu {
  z-index: 30;
}
</style>
