<template>
  <div class="is-flex">
    <div class="first-static-table">
      <table class="table">
        <thead>
          <tr>
            <th colspan="5">&nbsp;</th>
          </tr>
          <tr>
            <th>Categorie</th>
            <th colspan="2">Prestation</th>
            <th colspan="2">Total</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(category, catIndex) in categories">
            <tr :key="catIndex" style="height: 75px">
              <td class="has-background-primary has-text-white">
                {{ category.categoryName }}
              </td>
              <td class="has-background-primary">&nbsp;</td>
              <td class="has-background-primary has-text-white">
                <div class="is-flex is-flex-direction-column">
                  <span class="has-text-weight-bold">RAD</span>
                </div>
              </td>
              <td class="has-background-primary">
                <div class="is-flex is-flex-direction-column">
                  <span class="has-text-white has-text-weight-bold">{{
                    category.rad
                  }}</span>
                </div>
              </td>
              <td v-if="isEditing" class="has-background-primary">&nbsp;</td>
            </tr>
            <template v-for="prestation in category.prestations">
              <tr :key="'p-' + prestation.prestationId" style="height: 75px">
                <td>{{ prestation.subCategoryName }}</td>
                <td>{{ prestation.prestationName }}</td>
                <td>
                  <div class="is-flex is-flex-direction-column">
                    <span>RAD</span>
                  </div>
                </td>
                <td>
                  <div class="is-flex is-flex-direction-column">
                    <span
                      :class="{
                        danger:
                          prestationVariances[prestation.prestationId] !== 0,
                      }"
                      :title="prestationVariances[prestation.prestationId]"
                      >{{ prestation.rad }}</span
                    >
                  </div>
                </td>
                <td v-if="isEditing">
                  <span @click="smooth(prestation, 'all')">
                    <font-awesome-icon icon="redo-alt" class="is-info" />
                  </span>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <div class="sheet-data" style="overflow: auto; width: 100%">
      <table class="table" style="width: 100%">
        <thead>
          <tr>
            <template v-for="(yearData, yearIndex) in forecast">
              <th
                :colspan="
                  yearData.collapsed ? 1 : Object.keys(yearData.months).length
                "
                class="has-text-centered nowrap"
                :key="yearIndex"
              >
                <div
                  class="button is-small is-light collapse-button mr-2"
                  @click="
                    $set(forecast[yearIndex], 'collapsed', !yearData.collapsed)
                  "
                >
                  <font-awesome-icon icon="minus" v-if="!yearData.collapsed" />
                  <font-awesome-icon icon="plus" v-else />
                </div>
                <span>{{ yearData.year }}</span>
              </th>
            </template>
          </tr>
          <tr>
            <template v-for="(yearData, yearIndex) in forecast">
              <template v-for="(month, monthIndex) in yearData.months">
                <th
                  :key="`${yearIndex}-${monthIndex}`"
                  :class="{
                    collapsed: yearData.collapsed,
                    'table-border': monthIndex === 0,
                  }"
                  class="has-text-centered"
                >
                  {{ month.month | monthName }}
                </th>
              </template>
              <th
                :key="yearIndex"
                v-if="yearData.collapsed"
                class="table-border"
              >
                &nbsp;
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="(category, catIndex) in categories">
            <tr :key="catIndex" style="height: 75px">
              <template v-for="(yearData, yearIndex) in forecast">
                <template v-for="(month, monthIndex) in yearData.months">
                  <td
                    :key="`${yearIndex}-${monthIndex}`"
                    :class="{
                      collapsed: yearData.collapsed,
                      'table-border': monthIndex === 0,
                    }"
                    style="height: 60px"
                    class="has-background-primary has-text-centered"
                  >
                    <div class="is-flex is-flex-direction-column">
                      <span class="has-text-white has-text-weight-bold">{{
                        month.categories[category.categoryId]
                      }}</span>
                    </div>
                  </td>
                </template>
                <td
                  :key="yearIndex"
                  v-if="yearData.collapsed"
                  style="height: 60px"
                  class="table-border has-background-primary has-text-centered"
                >
                  <div class="is-flex is-flex-direction-column">
                    <span class="has-text-white has-text-weight-bold">{{
                      yearData.categories[category.categoryId]
                    }}</span>
                  </div>
                </td>
              </template>
            </tr>
            <template v-for="prestation in category.prestations">
              <tr :key="'p-' + prestation.prestationId" style="height: 75px">
                <template v-for="(yearData, yearIndex) in forecast">
                  <template v-for="(month, monthIndex) in yearData.months">
                    <td
                      :key="`${yearData.year}-${month.month}`"
                      class="has-text-centered nowrap"
                      :class="{
                        collapsed: yearData.collapsed,
                        'table-border': monthIndex === 0,
                      }"
                      style="height: 60px"
                    >
                      <div>
                        <span v-if="!isEditing">
                          {{ month.prestations[prestation.prestationId] }}
                        </span>
                        <input
                          class="input"
                          v-else
                          v-model="
                            forecast[yearIndex].months[monthIndex].prestations[
                              prestation.prestationId
                            ]
                          "
                          @input="
                            onFieldInput(
                              parseInt(yearIndex),
                              yearData.year,
                              parseInt(monthIndex),
                              month.month,
                              category.categoryId,
                              prestation,
                              month.prestations[prestation.prestationId]
                            )
                          "
                        />
                      </div>
                      <div
                        class="is-flex is-justify-content-space-around"
                        v-if="
                          isEditing &&
                          editingField &&
                          editingField.year === yearData.year &&
                          editingField.month === month.month &&
                          editingField.prestationId === prestation.prestationId
                        "
                      >
                        <span
                          v-if="!(yearIndex == 0 && monthIndex == 0)"
                          @click="
                            smooth(prestation, 'before')
                          "
                          title="lisse à gauche"
                        >
                          <font-awesome-icon
                            class="is-one-directional"
                            icon="chevron-left"
                            :title="
                              difference &&
                              difference[
                                prestation.prestationId
                              ].right.toString()
                            "
                          />
                        </span>
                        <span
                          v-if="
                            !(
                              (yearIndex == 0 && monthIndex == 0) ||
                              (yearIndex == Object.keys(forecast).length - 1 &&
                                monthIndex ==
                                  Object.keys(yearData.months).length - 1)
                            )
                          "
                          @click="
                            smooth(prestation, 'rest')
                          "
                          title="lisser le reste"
                          class="is-bi-directional"
                        >
                          <font-awesome-icon
                            icon="ellipsis-h"
                            :title="
                              difference &&
                              difference[
                                prestation.prestationId
                              ].editing.toString()
                            "
                          />
                        </span>
                        <span
                          v-if="
                            !(
                              yearIndex == Object.keys(forecast).length - 1 &&
                              monthIndex ==
                                Object.keys(yearData.months).length - 1
                            )
                          "
                          @click="
                            smooth(prestation, 'after')
                          "
                          title="lisse à droite"
                        >
                          <font-awesome-icon
                            class="is-one-directional"
                            icon="chevron-right"
                            :title="
                              difference &&
                              difference[
                                prestation.prestationId
                              ].left.toString()
                            "
                          />
                        </span>
                      </div>
                    </td>
                  </template>
                  <td
                    :key="yearIndex"
                    v-if="yearData.collapsed"
                    style="height: 60px"
                    class="table-border has-text-centered"
                  >
                    <div class="is-flex is-flex-direction-column">
                      <span>{{
                        yearData.prestations[prestation.prestationId]
                      }}</span>
                    </div>
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import axios from "axios";
import { debounce } from "lodash";

export default {
  name: "forecast-tab",
  props: {
    value: {},
  },
  data() {
    return {
      savedForecasts: [],
      forecast: {},
      calendar: [],
      // todo: forecast and calendar only keep one
      categories: [],
      loading: false,
      editingField: null,
      difference: null,
    };
  },

  computed: {
    ...mapGetters({
      isEditing: "App/isEdition",
      getRights: "Auth/getRights",
    }),
    prestations() {
      let prestations = [];
      for (const category of this.categories) {
        for (const prestation of category.prestations) {
          prestations.push(prestation);
        }
      }
      return prestations;
    },
    monthDatas() {
      let monthDatas = [];
      for (const yearData of this.calendar) {
        for (const monthData of Object.values(yearData.months)) {
          // each monthData.prestations is a vertical column
          monthDatas.push(monthData);
        }
      }
      return monthDatas;
    },
    prestationSum() {
      let prestationSum = {};
      for (const monthData of this.monthDatas) {
        // const amount = monthData.prestations[prestationId];
        // if (
        //   monthData.year <= this.editingField.year &&
        //   monthData.month <= this.editingField.month
        // ) {
        //   leftAmount = this.countMoney("+", leftAmount, amount);
        // }
        // if (
        //   monthData.year >= this.editingField.year &&
        //   monthData.month >= this.editingField.month
        // ) {
        //   rightAmount = this.countMoney("+", rightAmount, amount);
        // }
        
        // each monthData.prestations is a vertical column
        for (const [prestationId, amount] of Object.entries(
          monthData.prestations
        )) {
          prestationSum[prestationId] = this.countMoney(
            "+",
            prestationSum[prestationId] || 0,
            amount
          );
        }
      }
      return prestationSum;
    },
    prestationVariances() {
      let prestationVariances = {};
      for (const prestation of this.prestations) {
        prestationVariances[prestation.prestationId] = this.countMoney(
          "-",
          prestation.rad,
          this.prestationSum[prestation.prestationId] || 0
        );
      }
      return prestationVariances;
    },
    prestationErrors() {
      let prestationErrors = [];
      for (const prestation of this.prestations) {
        for (const [prestationId, variance] of Object.entries(
          this.prestationVariances
        )) {
          if (variance !== 0 && prestation.prestationId == prestationId) {
            prestationErrors.push(prestation.prestationName);
          }
        }
      }
      return prestationErrors;
    },
    forecastList() {
      let forecastList = [];
      for (const monthData of this.monthDatas) {
        for (const prestation of this.prestations) {
          const field = {
            sheetId: this.value.id,
            categoryId: prestation.categoryId,
            prestationId: prestation.prestationId,
            year: monthData.year,
            month: monthData.month,
            amount: monthData.prestations[prestation.prestationId],
          };
          forecastList.push(field);
        }
      }
      return forecastList;
    },
  },
  mounted() {
    this.initValues();
  },
  watch: {
    value() {
      this.initValues();
    },
    editingField(val) {
      if (!val) return;
      this.getDifference();
    },
    isEditing(val) {
      if (!val) {
        this.editingField = null;
      }
    },
  },
  methods: {
    getDifference() {
      const prestationId = this.editingField.prestationId;
      const rad = this.editingField.rad;
      const amount = this.editingField.amount;
      let leftAmount = 0;
      let rightAmount = 0;

      for (const [yearIndex, yearData] of Object.entries(this.forecast)) {
        for (const [monthIndex, monthData] of Object.entries(yearData.months)) {
          const amount = monthData.prestations[prestationId];
          if (
            yearIndex <= this.editingField.yearIndex &&
            monthIndex <= this.editingField.monthIndex
          ) {
            leftAmount = this.countMoney("+", leftAmount, amount);
          }
          if (
            yearIndex >= this.editingField.yearIndex &&
            monthIndex >= this.editingField.monthIndex
          ) {
            rightAmount = this.countMoney("+", rightAmount, amount);
          }
        }
      }
      const difference = {
        left: this.countMoney("-", rad, leftAmount),
        editing: this.countMoney("-", rad, amount),
        right: this.countMoney("-", rad, rightAmount),
      };
      if (!this.difference) {
        this.difference = {};
      }
      this.$set(this.difference, prestationId, difference);
    },
    smooth(prestation, direction) {
      const prestationId = prestation.prestationId;
      let redistributionAmount = 0;
      let workingMonthsCount = 0;
      this.calendar.forEach((yearData, yearIndex) => {
        for (const [monthIndex, monthData] of Object.entries(yearData.months)) {
          switch (direction) {
            case "before":
              redistributionAmount = this.difference[prestationId].right;
              if (
                yearIndex < this.editingField.yearIndex ||
                (yearIndex === this.editingField.yearIndex &&
                  monthIndex < this.editingField.monthIndex)
              ) {
                workingMonthsCount += 1;
                this.$set(monthData, "working", true);
              }
              break;
            case "after":
              redistributionAmount = this.difference[prestationId].left;
              if (
                yearIndex > this.editingField.yearIndex ||
                (yearIndex === this.editingField.yearIndex &&
                  monthIndex > this.editingField.monthIndex)
              ) {
                workingMonthsCount += 1;
                this.$set(monthData, "working", true);
              }
              break;
            case "rest":
              redistributionAmount = this.difference[prestationId].editing;
              if (
                yearIndex != this.editingField.yearIndex ||
                (yearIndex === this.editingField.yearIndex &&
                  monthIndex != this.editingField.monthIndex)
              ) {
                workingMonthsCount += 1;
                this.$set(monthData, "working", true);
              }
              break;
            case "all":
              redistributionAmount = prestation.rad;
              workingMonthsCount += 1;
              this.$set(monthData, "working", true);
              break;
          }
        }
      });
      let quotient = redistributionAmount / workingMonthsCount;
      quotient = this.formatMoney(quotient);
      let lastMonthQuota = this.countMoney(
        "-",
        redistributionAmount,
        quotient * (workingMonthsCount - 1)
      );
      let workCount = 0;
      for (const yearData of this.calendar) {
        for (const monthData of Object.values(yearData.months)) {
          if (monthData.working === true) {
            workCount += 1;
            let amount = 0;
            if (workCount < workingMonthsCount) {
              amount = quotient;
            } else if (workCount === workingMonthsCount) {
              amount = lastMonthQuota;
            } else {
              break;
            }
            this.$set(monthData.prestations, prestationId, amount);
            // todo: also set the category monthly sum or make it reactive
            this.$set(monthData, "working", false);
          }
        }
      }
      if (direction === "all") return;
      this.getDifference();
    },

    onFieldInput: debounce(function (
      yearIndex,
      yr,
      monthIndex,
      mon,
      categoryId,
      prestation,
      rawInput
    ) {
      const parsedInput = parseFloat(
        rawInput.replace(/[^\d.]/g, "").replace(/(\.\d{2})\d+/g, "$1")
      );
      const amount = isNaN(parsedInput) ? 0 : parsedInput;

      this.editingField = {
        yearIndex: yearIndex,
        year: yr,
        monthIndex: monthIndex,
        month: mon,
        categoryId: categoryId,
        prestationId: prestation.prestationId,
        rad: prestation.rad,
        amount: amount,
      };

      if (amount !== rawInput) {
        this.$set(
          this.forecast[yearIndex].months[monthIndex].prestations,
          prestation.prestationId,
          amount
        );
      }
    },
    1000),

    getSavedForecasts() {
      return axios.get(`/forecasts/${this.value.id}`).then((response) => {
        this.savedForecasts = response.data;
      });
    },
    initValues() {
      if (!this.value.project) return;

      // Start the forecast one month later than now.
      const startDate = moment().add(1, "month");
      const endDate = moment(this.value.endDate);

      // Generate the calendar
      const calendar = this.generateCalendar(startDate, endDate);
      this.calendar = calendar;
      const calendarObj = calendar.reduce((obj, item, index) => {
        obj[index] = item;
        return obj;
      }, {});

      this.$set(this, "forecast", calendarObj);

      // Retrieve the data and populate the forecast
      this.loading = true;
      this.getSavedForecasts().then(() => {
        this.$Api.Sheet.fetchSheetVm.bind(
          this,
          this.value.id
        )((data) => {
          const categories = JSON.parse(JSON.stringify(data.categories));
          this.hydrateCalendar(calendar, categories);
          this.categories = categories;
          this.loading = false;
        });
      });
    },

    generateCalendar(current_date, end_date) {
      let calendar = [];

      const currentYear = moment(current_date).year();
      const currentMonth = moment(current_date).month();

      const endYear = moment(end_date).year();
      const endMonth = moment(end_date).month();

      // Go from the current year til the end date year of the sheet.
      for (let i = currentYear; i <= endYear; i++) {
        // Evaluate the number of months left in the year
        const startingMonth = currentYear === i ? currentMonth : 0;
        const endingMonth = endYear === i ? endMonth + 1 : 12;

        // Prepare the year data
        let year = {
          year: i,
          collapsed: false,
          months: {},
          categories: {},
          prestations: {},
        };

        // Create the months
        let monthIndex = 0;
        for (let j = startingMonth; j < endingMonth; j++) {
          let month = {
            year: year.year,
            month: j + 1, // Month between 1-12
            categories: {},
            prestations: {},
          };
          this.$set(year.months, monthIndex, month);
          monthIndex += 1;
        }

        calendar.push(year);
      }
      return calendar;
    },
    hydrateCalendar(calendar, categories) {
      // the calendar and the forecast points to same objects, so forecast is also updated
      const totalMonths = calendar.reduce(
        (acc, year) => acc + Object.keys(year.months).length,
        0
      );
      // Skip the hydration if there are no months in the forecast.
      if (totalMonths <= 0) {
        return;
      }
      let hydratedMonths = 0;
      // let categoryRemainder = 0;
      let categoryRemainder = {};
      let prestationRemainder = {};
      // let prestationRemainder = 0;
      // Iterate through the calendar
      calendar.forEach((year, yearIndex) => {
        // Set the RAD for each categories and prestations by year
        categories.forEach((category) => {
          // todo: here is setting yearly sum for category and prestation, but it has a mismatch in sum with RAD, should deal with remainder?
          // first several years use quota ratio, last year use remainder to avoid years sum != RAD
          // this value is just for display, should not used in RAD sum check, use monthly sum instead
          const yearlyQuotaRatio =
            Object.keys(year.months).length / totalMonths;
          year.categories[category.categoryId] = this.formatMoney(
            category.rad * yearlyQuotaRatio
          );
          category.prestations.forEach((prestation) => {
            year.prestations[prestation.prestationId] = this.formatMoney(
              prestation.rad * yearlyQuotaRatio
            );
          });
        });
        // Set the data for each months
        Object.entries(year.months).forEach(([monthIndex, month]) => {
          // Set the RAD for each categories and prestations
          categories.forEach((category) => {
            if (yearIndex == 0 && monthIndex == 0) {
              categoryRemainder[category.categoryId] = category.rad;
            }
            const savedForecastByYearMonthCategory = this.savedForecasts.filter(
              (obj) =>
                obj.sheetId === this.value.id &&
                obj.year === year.year &&
                obj.month === month.month &&
                obj.categoryId === category.categoryId
            );
            let amount = 0;
            if (savedForecastByYearMonthCategory.length) {
              amount = savedForecastByYearMonthCategory.reduce((sum, obj) => {
                // return sum + obj.amount;
                return this.countMoney("+", sum, obj.amount);
              }, 0);
            } else if (hydratedMonths === totalMonths - 1) {
              // last month
              amount = this.formatMoney(categoryRemainder[category.categoryId]);
            } else {
              amount = this.formatMoney(category.rad / totalMonths);
            }
            this.$set(
              year.months[monthIndex].categories,
              category.categoryId,
              amount
            );
            categoryRemainder[category.categoryId] = this.countMoney(
              "-",
              categoryRemainder[category.categoryId],
              amount
            );

            category.prestations.forEach((prestation) => {
              if (yearIndex == 0 && monthIndex == 0) {
                prestationRemainder[prestation.prestationId] = prestation.rad;
              }
              const savedForecastByCategoryPrestationYear =
                this.savedForecasts.filter(
                  (obj) =>
                    obj.sheetId === this.value.id &&
                    obj.categoryId === category.categoryId &&
                    obj.prestationId === prestation.prestationId &&
                    obj.year === year.year
                );

              if (savedForecastByCategoryPrestationYear.length === 0) {
                if (hydratedMonths === totalMonths - 1) {
                  amount = this.formatMoney(
                    prestationRemainder[prestation.prestationId]
                  );
                } else {
                  amount = this.formatMoney(prestation.rad / totalMonths);
                }
              } else {
                // apply the db forecast to each month, if sheet added new months, that month is 0
                const currentMonth = savedForecastByCategoryPrestationYear.find(
                  (record) => record.month === month.month
                );
                amount = (currentMonth && currentMonth.amount) || 0;
              }
              this.$set(
                year.months[monthIndex].prestations,
                prestation.prestationId,
                amount
              );

              prestationRemainder[prestation.prestationId] = this.countMoney(
                "-",
                prestationRemainder[prestation.prestationId],
                amount
              );
            });
          });
          hydratedMonths += 1;
        });
      });
    },
    formatMoney(number) {
      return Math.trunc(number * 100) / 100;
    },
    countMoney(operator, a, b) {
      switch (operator) {
        case "+":
          return (Math.round(a * 100) + Math.round(b * 100)) / 100;
        case "-":
          return (Math.round(a * 100) - Math.round(b * 100)) / 100;
      }
    },
    save() {
      if (this.prestationErrors.length) {
        this.$awn.warning(
          `La sommes total des mois de la prestation ${this.prestationErrors} ne correspondent pas au total du RAD.`
        );
        return;
      }
      axios.post(`/forecasts/${this.value.id}`, this.forecastList).then(() => {
        this.$store.dispatch("App/setEditing", false);
        this.initValues();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.collapsed {
  display: none;
}

td.table-border {
  border-color: #dbdbdb;
  border-width: 0 0 1px 2px;
  border-style: solid;
  &.is-info {
    border-bottom-color: $axione-blue;
  }
}

th.table-border {
  border-color: #dbdbdb;
  border-width: 2px 0 2px 2px;
  border-style: solid;
}

.is-info {
  color: $axione-blue;
}

.danger {
  color: red;
  font-weight: 600;
}

.input {
  width: 90px;
}

.is-one-directional {
  color: $axione-blue;
}

.is-bi-directional {
  color: $axione-orange;
}
</style>
